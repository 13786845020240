import { Box } from "@mui/material";
import { useTheme } from "@mui/material";
import AddIcon from '@mui/icons-material/Add';
import WarehouseIcon from '@mui/icons-material/Warehouse';
import Shbutton from "../../../../../components/theme/buttons/Shbutton";
import ShDataGrid from "../../../../../components/theme/datagrid/ShDataGrid";
import { tokens } from "../../../../../theme";
import ShLabel from "../../../../../components/theme/label/ShLabel";
import Text from "../../../../../components/theme/text/Text";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from 'react-i18next';
import { useGetStockMutationsQuery } from "../../../../../newapi/warehouse/stockSlice";
import SPStockDetails from "../../../../../components/global/Sidepanels/SpStockDetails/SPStockDetails";
import SPLocationDetails from "../../../../../components/global/Sidepanels/SpLocationDetails/SpLocationDetails";
import StockMutationCard from "../../../../../components/theme/cards/stock/StockMutationCard";
import ButtonGroup from "../../../../../components/theme/buttons/ButtonGroup";
import ShTextSkeleton from "../../../../../components/theme/skeleton/ShTextSkeleton";
import ShDoubleTextSkeleton from "../../../../../components/theme/skeleton/ShDoubleTextSkeleton";
import ShLabelSkeleton from "../../../../../components/theme/skeleton/ShLabelSkeleton";
import { useSelector } from "react-redux";

import { useNavigate } from 'react-router-dom';
import { checkUserPermission, UserPrivileges, UNAUTHORIZED_ENDPOINT } from '../../../../../utils/permissions';
import ShDataGrid2 from "../../../../../components/theme/datagrid2/ShDataGrid2";

const GridActions = (props) => {
    const theme = useTheme();
    return (
        <ButtonGroup version={2}>
            <Shbutton 
                className={"group-btn"} 
                color={theme.palette.mode === "dark" ? "blue" : "primary"} 
                // onClick={confirm} 
                endIcon={<AddIcon/>}
                variant="contained"
            >
                New
            </Shbutton>
        </ButtonGroup>
        // <Box display="flex" alignItems="center" justifyContent="space-between" flex={1} gap={2}>
        //     <Shbutton isSidepanel color="primary" variant="contained" endIcon={<AddIcon/>}>New</Shbutton>
        // </Box>
    )
}

let gridOptions = {
    export:true,
    disableCustomActionsOnPhone: true,
    isSidepanel: true,
    filter : {
        name: "mutationsFilter",
        selectedFilter : null,
        groups : [
            {
                conditionExtensionType : 0,
                rules : [
                ]
            }
        ]
    }
};

export default (props) => {
    // Check if user has permission to access the page
    const navigate = useNavigate();
    useEffect(() => {
        if (!checkUserPermission([UserPrivileges.STOCK_READ])) {
        navigate(UNAUTHORIZED_ENDPOINT); // Use navigate instead of history.push
        }
    }, [navigate]);

    const { t, i18n,ready } = useTranslation();

    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    
    const isFulfilment = useSelector((state) => state.settings.environment.isFulfilment);
    const [locationIsExtended, setLocationIsExtended] = useState(false);

  

    useEffect(() => {
        props.replaceView({
            ...props.config,
            gridOptions: gridOptions,
        });

        return () => {
            // console.log("unmounting warehouse");
        }
    },[]);

    const [productData, setProductData] = useState({});
    const getProductDetails = (params) => {
        // getProduct(params.id,(result, data) => {
        //     if (result) {
        //         console.log(data);
        //         setProductData(data);
        //         setIsExtended(true);
        //     }
        // });
    }

    const [locationId, setLocationId] = useState();
    const locationIdRef = useRef(locationId);
    
    const getLocationDetails = (params) => {
        if (!params) return;
    
        const newProductId = parseInt(params.row.stock?.warehouseLocation?.id);
        // Only update state if the productId has actually changed
        if (locationIdRef.current !== newProductId) {
            setLocationId(newProductId);
            locationIdRef.current = newProductId; // Update the ref to the new value
        }
        setLocationIsExtended(true);
    };

    const columns = [
        {
            field: "product",
            headerName: t('product'),
            flex:1,
            renderCell : (params) => {

                if(params.row.skeleton) {
                    return <ShDoubleTextSkeleton/>
                }

                return(
                    <Box display={"flex"} flexDirection={"column"}>
                        <Text bold>
                        {params.row.stock.product?.sku}
                        </Text>
                        <Text light>
                        {params.row.stock.product?.description}
                        </Text>
                    </Box>
                )
            }
        },
        {
            field: "location",
            headerName: t('location'),
            flex:1,
            renderCell : (params) => {
                if(params.row.skeleton) {
                    return <ShLabelSkeleton variant={"yellow"}/>
                }

                return (
                    <Box onClick={(event) => {
                        event.stopPropagation(); // Prevent click from propagating to the row
                        getLocationDetails(params);
                    }}
                        sx={{
                            cursor:"pointer"
                        }}
                    >
                        <ShLabel size={32} fitted variant={"yellow"}>
                            {params.row.stock.warehouseLocation?.location}
                        </ShLabel>
                    </Box>
                )
            },
        },
        {
            field: "created",
            headerName: t('created'),
            flex:1,
            renderCell : (params) => {
                if(params.row.skeleton) {
                    return <ShDoubleTextSkeleton/>
                }

                return (
                    <Box display={"flex"} flexDirection={"column"}>
                        <Text fontWeight="500">
                            {typeof params.row.createdAt !== "undefined" ?
                                new Date(params.row.createdAt).toLocaleDateString(i18n.language)
                            : null}
                        </Text>
                        <Text light>
                            {typeof params.row.createdAt !== "undefined" ?
                                new Date(params.row.createdAt).toLocaleTimeString(i18n.language)
                            : null}
                        </Text>
                    </Box>
                )
            },
        },
        {
            field: "quantity",
            headerName: t('quantity'),
            flex:1,
            renderCell : (params) => {
                if(params.row.skeleton) {
                    return <ShTextSkeleton/>
                }
                return params.row.mutation
            },
        },
        {
            field: "mutationtype",
            headerName: t('mutationtype'),
            flex:1,
            renderCell : (params) => {
                if(params.row.skeleton) {
                    return <ShTextSkeleton/>
                }
                return params.row.type
            },
        },
        ...isFulfilment ? [{
            field: "relation",
            headerName: t('relation'),
            flex:1,
            renderCell : (params) => {
                if(params.row.skeleton) {
                    return <ShTextSkeleton/>
                }
                return params.row.relation?.name
            },
        }] : []
        ,
        {
            field: "user",
            headerName: t('user'),
            flex:1,
            renderCell : (params) => {
                if(params.row.skeleton) {
                    return <ShTextSkeleton/>
                }
                return params.row.userName
            },
        },
        {
            field: "comment",
            headerName: t('comment'),
            flex:1,
            renderCell : (params) => {
                if(params.row.skeleton) {
                    return <ShTextSkeleton/>
                }
                return params.row.remarks
            },
        },
        {
            field: "reference",
            headerName: t('reference'),
            flex:1,
            renderCell : (params) => {
                if(params.row.skeleton) {
                    return <ShTextSkeleton/>
                }
                return "Unknown"
            },
        },
        {
            field: "phoneView", 
            headerName: "PhoneView",
            cellClassName: "phone-column--cell",
            // hide: true,
            renderCell: (params) => {
                // console.log(cellValues.row);
                return (
                    <StockMutationCard skeleton={params.row.skeleton ?? false} {...params.row}/>
                    // <Box
                    //     sx={{
                    //         background:`${theme.palette.mode === "dark" ? colors.grey[300] : colors.grey[100]}`,
                    //         paddingX:2,
                    //         paddingY:1.5,
                    //         borderRadius:2
                    //     }}
                    // >

                    // </Box>
                )
            }
        }
    ];

    return (
        <Box height={1} display={"flex"} flexDirection={"column"}>
            <ShDataGrid2 key={theme.palette.mode}
                content={useGetStockMutationsQuery}
                // gridActions={<GridActions></GridActions>} 
                title={t("mutations")}
                gridOptions={gridOptions} 
                // onRowClick={getProductDetails}
                isSidepanelGrid 
                columns={columns}>
            </ShDataGrid2>

            <SPLocationDetails
                isExtended={locationIsExtended}
                setIsExtended={setLocationIsExtended}
                locationId={locationId}
                // data={locationData} // Pass the productData as a prop
            ></SPLocationDetails>
            {/* <SPStockDetails
                isExtended={isExtended}
                setIsExtended={setIsExtended}
                data={productData}
            ></SPStockDetails>
            <SPLocationDetails
                isExtended={locationIsExtended}
                setIsExtended={setLocationIsExtended}
                data={locationData}
            ></SPLocationDetails> */}
        </Box>
    );
}