import { Box, InputBase } from "@mui/material";
import { useTheme } from "@mui/material";
import { tokens } from "../../../../../theme";
import Shbutton from "../../../../theme/buttons/Shbutton";
import SaveIcon from '@mui/icons-material/Save';
import Text from "../../../../theme/text/Text";
import Dropdown from "../../../../theme/dropout/DropOut";
import { useEffect, useState } from "react";
import ButtonGroup from "../../../../theme/buttons/ButtonGroup";
import { useUpdateCountingStrategyTemplateMutation, useDeleteCountingStrategyTemplateMutation } from "../../../../../newapi/countingstrategy/countingStrategyTemplateSlice";

import { useTranslation } from "react-i18next";
import { toast } from 'react-toastify';
import { showToastMessageDeleteRequest, showToastMessageRequestError, showToastMessageUpdateRequest } from "../../../../../utils/toasts";
import { useGetEnumsQuery } from "../../../../../newapi/global/generalApiSlice";
import DeleteIcon from '@mui/icons-material/Delete';
import MConfirmDelete from "../../../Modals/MConfirmDelete";

import * as yup from "yup";
import ShValidatedInput from "../../../../theme/inputs/ShValidatedInput";
import ShValidatedNumberInput from "../../../../theme/inputs/ShValidatedNumberInput";
import ShValidatedInputDropdown from "../../../../theme/dropdowns/ShValidatedInputDropdown";
import { yupRequired } from "../../../../../utils/validation";
import EMCountingStrategyAmount from "../../../ModalsPopover/explainers/countingstrategy/EMCountingStrategyAmount";

const GridActions = (props) => {
    const theme = useTheme();
    const { t } = useTranslation();

    return (
        <ButtonGroup version={2}>
            <Shbutton
                className={"group-btn"}
                onClick={props.deleteAction}
                color="red"
                variant="contained"
                endIcon={<DeleteIcon />}
            >
                {t('delete')}
            </Shbutton>
            <Shbutton
                className={"group-btn"}
                onClick={props.action}
                color={theme.palette.mode === "dark" ? "blue" : "primary"}
                variant="contained"
                endIcon={<SaveIcon />}
            >
                {t('save')}
            </Shbutton>
        </ButtonGroup>
    );
};

const DetailsPanel = (props) => {
    const  { t } = useTranslation();
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const [open, setOpen] = useState(false);
    

    const [countingStrategyInfo, setCountingStrategyInfo] = useState({
        name: "",
        type: "",
        frequency: "",
        amount: ""
    });

    const [fieldErrors, setFieldErrors] = useState({});

    const rules = {
        name: yupRequired('name', t),
        type: yupRequired('type', t),
        frequency: yupRequired('frequency', t),
        amount: yup.number()
                .required(yupRequired('amount', t))
                .min(1, t('amount_greater_than_zero'))
    };
    
    const schema = yup.object().shape(rules);

    useEffect(() => {
        if (props.data){
            setCountingStrategyInfo(props.data);
        }
    },[props.data]);
    
    const handleChange = (key, value) => {
        console.log(key,value);
        setCountingStrategyInfo({
          ...countingStrategyInfo,
          [key]: value
        });
      };

      
    useEffect(() => {
        props.replaceView({
            ...props.config,
            mobileActions:<GridActions data={countingStrategyInfo}></GridActions>,
        });

        return () => {
            // console.log("unmounting warehouse");
        }
    },[countingStrategyInfo]);

    const [UpdateCountingStrategy,{isLoading}] = useUpdateCountingStrategyTemplateMutation();
    const handleSaveClick = async () => {
        console.log(countingStrategyInfo);
        try {
            setFieldErrors({});
            await schema.validate(countingStrategyInfo, { abortEarly: false });
            const data = await UpdateCountingStrategy(countingStrategyInfo).unwrap()
            showToastMessageUpdateRequest(t,data);
        } catch (err) {
            if (err.name === 'ValidationError') {
                const errors = err.inner.reduce((acc, currentError) => ({
                    ...acc,
                    [currentError.path]: currentError.message
                }), {});
            
                setFieldErrors(errors);
            } else {
                showToastMessageRequestError(t("update_failed"),t,err);
            }
        }      
    };

    const [DeleteCountingStrategy] = useDeleteCountingStrategyTemplateMutation();
    const confirmDelete = async () => {
        try {
            const response = await DeleteCountingStrategy(props.data.id).unwrap();
            showToastMessageDeleteRequest(t, response);
            props.setIsExtended(false);
        } catch (err) {
            console.log(err);
            showToastMessageDeleteRequest(t, {success: false, error: err.data.error});
        }
    };

    const handleDeleteClick = async () => {
        setOpen(true);
    };

    const confirmDeleteModal = {
        open: open,
        setOpen: setOpen,
        confirm: confirmDelete
    }

    const { data: globalEnums, enumIsLoading } = useGetEnumsQuery("counting_strategy_type,time_based_frequencies");

    return (
        <Box height={1} display={"flex"} flexDirection={"column"} paddingX={3}
            sx={{
                "@media screen and (min-width: 48.1em)"  :{
                    marginBottom:11
                }
            }}
        >
            <Dropdown title={t("basic")} open>
                <Box sx={{
                    display:"flex",
                    justifyContent:"space-between",
                    "@media screen and (max-width: 47.9375em)" : {
                        flexDirection:"column"
                    }
                }}>
                    
                    {/* Two columns */}
                    
                    <Box flex={4}
                        sx={{
                            display:"flex",
                            justifyContent:"flex-start",
                            flexDirection:"column",
                            gap:1,
                        }}
                    >
                         <Box
                            sx={{
                                display:"flex",
                                
                            }}
                        >
                            <Box 
                                sx={{
                                    height:40,
                                    width:220,
                                    lineHeight:"40px",
                                    flexShrink:0,
                                }}
                            >
                                <Text>{t("name")}</Text>
                            </Box>
                            
                            <Box
                            
                                sx={{
                                    width:220,
                                    
                                }}
                            >
                                <ShValidatedInput
                                    height="40"
                                    name="name"
                                    value={countingStrategyInfo.name}
                                    onChange={handleChange}
                                    error={fieldErrors.name}
                                />
                            </Box>

                

                          
                        </Box>

                        <Box
                            sx={{
                                display:"flex",
                                
                            }}
                        >
                            <Box 
                                sx={{
                                    height:40,
                                    width:220,
                                    lineHeight:"40px",
                                    flexShrink:0,
                                }}
                            >
                            <Text>{t("frequency")}</Text>
                            </Box>
                            
                            <Box 
                            
                                sx={{
                                    width:220,
                                }}
                            >
                                  <ShValidatedInputDropdown
                                    height={40}
                                    name="frequency"
                                    displayName="displayName"
                                    changeField={"name"}
                                    selected={globalEnums ? globalEnums["time.based.frequencies"].findIndex(item => item === countingStrategyInfo.frequency) ?? -1 : -1}
                                    options={globalEnums ? globalEnums["time.based.frequencies"].map((item, index) => ({ id: index, name: item, displayName: t(item) })) : []}
                                    noSelection={t("choose_option")} 
                                    onChange={handleChange}
                                    error={fieldErrors.frequency}
                                />
                            </Box>
                        </Box>

                        <Box
                            sx={{
                                display:"flex",
                                
                            }}
                        >
                            <Box 
                                sx={{
                                    height:40,
                                    width:220,
                                    lineHeight:"40px",
                                    flexShrink:0,
                                }}
                            >
                                <Text>{t("type")}</Text>
                            </Box>
                            
                            <Box
                            
                                sx={{
                                    width:220,
                                }}
                            >
                                <ShValidatedInputDropdown
                                    name="type"
                                    displayName="displayName"
                                    changeField={"name"}
                                    options={globalEnums ? globalEnums["counting.strategy.type"].map((item, index) => ({ id: index, name: item, displayName: t(item) })) : []}
                                    selected={globalEnums ? globalEnums["counting.strategy.type"].findIndex(item => item === countingStrategyInfo.type) ?? -1 : -1}
                                    noSelection={t("choose_option")} 
                                    onChange={handleChange}
                                    error={fieldErrors.type}
                                />
                            </Box>
                        </Box>
                        <Box
                            sx={{
                                display:"flex",
                                
                            }}
                        >
                            <Box 
                                sx={{
                                    height:40,
                                    width:220,
                                    lineHeight:"40px",
                                    flexShrink:0,
                                    display: "flex",
                                    alignItems: "center"
                            }}>
                                <Text>... {t("_percent_of")} {countingStrategyInfo.type.length > 0 ? t(`_${countingStrategyInfo.type}`) : ""}</Text>
                                <EMCountingStrategyAmount></EMCountingStrategyAmount>
                            </Box>
                            
                            <Box
                            
                                sx={{
                                    width:220,
                                }}
                            >
                                <ShValidatedNumberInput
                                    name="amount"
                                    value={countingStrategyInfo.amount}
                                    onChange={handleChange}
                                    error={fieldErrors.amount}
                                />
                            </Box>
                        </Box>
                      
                        {/* <Box
                            sx={{
                                display:"flex",
                                flexDirection:"column",
                                gap:1,
                                "@media screen and (max-width: 47.9375em)" : {
                                    flex:1
                                }
                            }}
                        >
                            <Box lineHeight={"38px"}>
                                <Text>{t("name")}</Text>
                            </Box>
                            <Box lineHeight={"38px"}>
                                <Text>{t("frequency")}</Text>
                            </Box>
                            <Box lineHeight={"38px"}>
                                <Text>{t("type")}</Text>
                            </Box> 
                            <Box lineHeight={"38px"} sx={{
                                display: "flex",
                                alignItems: "center"
                            }}>
                                <Text>... {t("_percent_of")} {countingStrategyInfo.type.length > 0 ? t(`_${countingStrategyInfo.type}`) : ""}</Text>
                                <EMCountingStrategyAmount></EMCountingStrategyAmount>
                            </Box> 
                        </Box>

                        <Box
                            sx={{
                                
                                display:"flex",
                                flexDirection:"column",
                                gap:1,
                            }}
                        >
                            <Box display="flex"
                                height="38px"
                                // maxWidth="400px"
                                borderRadius="20px"
                                sx={{
                                    marginTop:0.5,
                                    marginBottom:0.5,
                                    justifyContent:"space-between",
                                    "@media screen and (max-width: 47.9375em)" : {
                                        flexDirection:"column"
                                    }
                                }}
                            >
                                <ShValidatedInput
                                    name="name"
                                    value={countingStrategyInfo.name}
                                    onChange={handleChange}
                                    error={fieldErrors.name}
                                />
                            </Box>
                            <Box 
                                sx={{
                                    lineHeight:"38px",
                                    "@media screen and (max-width:47.9375em)" : {
                                        display:"flex",
                                        justifyContent:"flex-end"
                                    }
                                }}
                            >
                                <ShValidatedInputDropdown
                                    name="frequency"
                                    displayName="displayName"
                                    selected={globalEnums ? globalEnums["time.based.frequencies"].findIndex(item => item === countingStrategyInfo.frequency) ?? -1 : -1}
                                    options={globalEnums ? globalEnums["time.based.frequencies"].map((item, index) => ({ id: index, name: item, displayName: t(item) })) : []}
                                    noSelection={t("choose_option")} 
                                    onChange={handleChange}
                                    error={fieldErrors.frequency}
                                />
                            </Box>
                            <Box 
                                sx={{
                                    lineHeight:"38px",
                                    "@media screen and (max-width:47.9375em)" : {
                                        display:"flex",
                                        justifyContent:"flex-end"
                                    }
                                }}
                                >
                                <ShValidatedInputDropdown
                                    name="type"
                                    displayName="displayName"
                                    options={globalEnums ? globalEnums["counting.strategy.type"].map((item, index) => ({ id: index, name: item, displayName: t(item) })) : []}
                                    selected={globalEnums ? globalEnums["counting.strategy.type"].findIndex(item => item === countingStrategyInfo.type) ?? -1 : -1}
                                    noSelection={t("choose_option")} 
                                    onChange={handleChange}
                                    error={fieldErrors.type}
                                />
                            </Box>
                            <Box display="flex"
                                height="38px"
                                // maxWidth="400px"
                                borderRadius="20px"
                                // backgroundColor={colors.grey[100]}
                                sx={{
                                    marginTop:0.5,
                                    marginBottom:0.5,
                                    justifyContent:"space-between",
                                    "@media screen and (max-width: 47.9375em)" : {
                                        flexDirection:"column"
                                    }
                                }}
                            >
                                <ShValidatedNumberInput
                                    name="amount"
                                    value={countingStrategyInfo.amount}
                                    onChange={handleChange}
                                    error={fieldErrors.amount}
                                />
                            </Box>
                        </Box> */}
                    </Box>
                </Box>
                <Box 
                sx={{
                    width:1,
                    display:"flex",
                    alignItems:"center",
                    justifyContent:"space-between",
                    gap:7
                }}
            >

                
            </Box>
            </Dropdown>
            <Box
                sx={{
                    position:"absolute",
                    bottom:0,
                    right:0,
                    padding:3,
                    width:1,
                    background:theme.palette.mode === "dark" ? colors.bg["tertiary"] : colors.grey[0],
                    "@media screen and (min-width: 47.9376em) and (max-width: 63.9375em)" : {
                        padding:2,
                        paddingY:2.5,
                    }
                }}
            >
                    {/* Pass the necessary state values and setter functions to GridActions */}
                    <MConfirmDelete
                open={confirmDeleteModal.open}
                handleClose={() => confirmDeleteModal.setOpen(false)}
                data={confirmDeleteModal.data}
                confirm={confirmDeleteModal.confirm}
            />
        <GridActions
            id={countingStrategyInfo?.id}
            action={handleSaveClick}
            deleteAction={handleDeleteClick}
            // active={active}
            // blocked={blocked}
            // remark={remark}
            // carrier={carrier}
            // quarantine={quarantine}
            // enableLinkingWhenBlocked={enableLinkingWhenBlocked}
        />
            </Box>
        </Box>
    );
}

export default DetailsPanel;