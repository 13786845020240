import { Box, Divider, FormControl, IconButton, InputBase, InputLabel, Switch, TextField, ToggleButton, ToggleButtonGroup } from "@mui/material";
import { DataGrid, GridToolbar, useGridApiRef } from "@mui/x-data-grid";
import { useTheme } from "@mui/material";
import ShDataGrid from "../../../../theme/datagrid/ShDataGrid";
import Inventory2Icon from '@mui/icons-material/Inventory2';
import { tokens } from "../../../../../theme";
import Shbutton from "../../../../theme/buttons/Shbutton";
import SaveIcon from '@mui/icons-material/Save';
import WarehouseIcon from '@mui/icons-material/Warehouse';
import Icon from "../../../../theme/icons/Icon";
import Text from "../../../../theme/text/Text";
import MenuItem from '@mui/material/MenuItem';
import Select, { SelectChangeEvent } from '@mui/material/Select';

import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import ModeEditOutlineOutlinedIcon from '@mui/icons-material/ModeEditOutlineOutlined';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import Dropdown from "../../../../theme/dropout/DropOut";
import { useEffect, useState } from "react";
import cx from "classnames"
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import RemoveOutlinedIcon from '@mui/icons-material/RemoveOutlined';
import NumberInput from "../../../../theme/inputs/NumberInput";
import ShSwitch from "../../../../theme/inputs/ShSwitch";
import CropFreeIcon from '@mui/icons-material/CropFree';
import ButtonGroup from "../../../../theme/buttons/ButtonGroup";
import { useEditUserMutation, useDeleteUserMutation } from "../../../../../newapi/user/userSlice";
import {checkAllPropertiesTruthy } from "../../../../../utils"

import { useTranslation } from "react-i18next";
import { toast } from 'react-toastify';
import SelectGroupMultiple from "../../../../theme/Select/SelectGroupMultiple";
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import DirectionsRunIcon from '@mui/icons-material/DirectionsRun';
import FavoriteOutlinedIcon from '@mui/icons-material/FavoriteOutlined';
import AutoAwesomeOutlinedIcon from '@mui/icons-material/AutoAwesomeOutlined';
import { useGetShippersSelectQuery } from "../../../../../newapi/shipper/shipperSlice";
import { useGetUsergroupsSelectQuery } from "../../../../../newapi/user/usergroupSlice";
import { useGetWarehousesSelectQuery } from "../../../../../newapi/warehouse/warehouseApiSlice";
import { InputDropdown } from "../../../../theme/dropdowns/InputDropdown";
import { showToastMessageDeleteRequest, showToastMessagePostRequest, showToastMessageRequestError, showToastMessageUpdateRequest } from "../../../../../utils/toasts";
import DeleteIcon from '@mui/icons-material/Delete';
import MConfirmDelete from "../../../Modals/MConfirmDelete";
import { useSelector } from "react-redux";

const GridActions = (props) => {
    const theme = useTheme();
    const { t } = useTranslation();

    return (
        <ButtonGroup version={2}>
          <Shbutton
                className={"group-btn"}
                onClick={props.deleteAction}
                color="red"
                variant="contained"
                endIcon={<DeleteIcon />}
            >
                {t('delete')}
            </Shbutton>
            <Shbutton
                className={"group-btn"}
                onClick={props.action}
                color={theme.palette.mode === "dark" ? "blue" : "primary"}
                variant="contained"
                endIcon={<SaveIcon />}
            >
                {t('save')}
            </Shbutton>
        </ButtonGroup>
    );
};

const DetailsPanel = (props) => {
    const  { t } = useTranslation();
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const customer_id = useSelector((state) => state.settings.environment.selectedId);

    const [userInfo, setUserInfo] = useState({
        id: 1,
        email: "",
        employee_code: "",
        type: 0,
        firstname: "",
        insertion: "",
        lastname: "",
        usergroups: []
    });

    
    const { data: usergroups } = useGetUsergroupsSelectQuery();
    const {data: warehouses } = useGetWarehousesSelectQuery();
    useEffect(() => {
      setUserInfo(props.data);
    },[props.data]);

    const handleChange = (key, value) => {
        let updatedUserInfo = JSON.parse(JSON.stringify(userInfo));
        if(key.includes("usergroupWarehouse")){
            const warehouseId = parseInt(key.split("#")[1]);
            const usergroupIndex = updatedUserInfo.usergroups.findIndex(usergroup => usergroup.warehouseId === warehouseId);
            if(usergroupIndex !== -1){
                if(value === -1){
                    updatedUserInfo.usergroups.splice(usergroupIndex, 1);
                } else {
                    updatedUserInfo.usergroups[usergroupIndex].id = usergroups[value].id;
                }
            } else {
              updatedUserInfo.usergroups.push({
                  id: usergroups[value].id,
                  warehouseId: warehouseId,
                  admin: false,
                  userPrivileges: null
              }); 
            }
        } else {
            updatedUserInfo[key] = value;
        }
        setUserInfo(updatedUserInfo);
    };

    

    useEffect(() => {
      console.log(userInfo);
        props.replaceView({
            ...props.config,
            mobileActions:<GridActions data={userInfo}></GridActions>,
        });

        return () => {
            // console.log("unmounting warehouse");
        }
        
    },[userInfo]);

    const [EditUser,{isLoading}] = useEditUserMutation();
    const handleSaveClick = async () => {
        const canSave = true;
        if(canSave){    
            try {
                const data = await EditUser({body: userInfo, customerId: customer_id}).unwrap()
                showToastMessagePostRequest(t, data);
            } catch (err) {
                showToastMessageRequestError(t("update_failed"), t, {success: false, error: err.error});
            }
        } else {
            if(!isLoading) {
                showToastMessageRequestError(t("not_all_fields_filled"), t, {success: false, error: ""});
          }
        }        
    };

   

    const [DeleteUserMutation] = useDeleteUserMutation();
      const confirmDelete = async () => {
        try {
          
            const response = await DeleteUserMutation({id: props.data.id, customerId: customer_id}).unwrap();
            showToastMessageDeleteRequest(t, response);
            props.setIsExtended(false);
        } catch (err) {
            showToastMessageDeleteRequest(t, {success: false, error: err.error});
        }
    };
    const handleDeleteClick = async () => {
        setOpen(true);
    };
    const [open, setOpen] = useState(false);
    const confirmDeleteModal = {
        open: open,
        setOpen: setOpen,
        confirm: confirmDelete
    } 


    return (
      <Box
        height={1}
        display={"flex"}
        flexDirection={"column"}
        paddingX={3}
        sx={{
          "@media screen and (min-width: 48.1em)": {
            marginBottom: 11,
          },
        }}
      >
        <Dropdown title={t("basic")} open>
          <Box
            sx={{
              display: "flex",
              gap: 4,
              justifyContent: "flex-start",
            }}
          >
            <Box
              sx={{
                "@media screen and (max-width: 47.9375em)": {
                  flex: 1,
                },
                width:"150px"
              }}
            >
              <Box lineHeight={"38px"}>
                <Text light>{t("employee_code")}</Text>
              </Box>
            </Box>
            <Box>
              <Box
                display="flex"
                height="32px"
                // maxWidth="400px"
                borderRadius="20px"
                backgroundColor={theme.palette.mode === "dark" ? colors.grey[300] : colors.grey[100]}
                sx={{
                  marginTop: 0.5,
                  marginBottom: 0.5,
                  justifyContent: "space-between",
                  "@media screen and (max-width: 47.9375em)": {
                    flexDirection: "column",
                  },
                }}
              >
                <InputBase
                  sx={{ ml: 2, flex: 1 }}
                  placeholder=""
                  value={userInfo?.employee_code || ""}
                  onChange={(e) =>
                    handleChange("employee_code", e.target.value)
                  }
                />
              </Box>
            </Box>
          </Box>

          <Box
            sx={{
              display: "flex",
              gap: 4,
              justifyContent: "flex-start",
            }}
          >
            <Box
              sx={{
                "@media screen and (max-width: 47.9375em)": {
                  flex: 1,
                },
                width:"150px"
              }}
            >
              <Box lineHeight={"38px"}>
                <Text light>{t("firstname")}</Text>
              </Box>
            </Box>
            <Box>
              <Box
                display="flex"
                height="32px"
                // maxWidth="400px"
                borderRadius="20px"
                backgroundColor={theme.palette.mode === "dark" ? colors.grey[300] : colors.grey[100]}
                sx={{
                  marginTop: 0.5,
                  marginBottom: 0.5,
                  justifyContent: "space-between",
                  "@media screen and (max-width: 47.9375em)": {
                    flexDirection: "column",
                  },
                }}
              >
                <InputBase
                  sx={{ ml: 2, flex: 1 }}
                  placeholder=""
                  value={userInfo?.firstname || ""}
                  onChange={(e) => handleChange("firstname", e.target.value)}
                />
              </Box>
            </Box>
          </Box>

          <Box
            sx={{
              display: "flex",
              gap: 4,
              justifyContent: "flex-start",
            }}
          >
            <Box
              sx={{
                "@media screen and (max-width: 47.9375em)": {
                  flex: 1,
                  // width:1
                },
                width:"150px"
              }}
            >
              <Box lineHeight={"38px"}>
                <Text light>{t("insertion")}</Text>
              </Box>
            </Box>
            <Box>
              <Box
                display="flex"
                height="32px"
                // maxWidth="400px"
                borderRadius="20px"
                backgroundColor={theme.palette.mode === "dark" ? colors.grey[300] : colors.grey[100]}
                sx={{
                  marginTop: 0.5,
                  marginBottom: 0.5,
                  justifyContent: "space-between",
                  "@media screen and (max-width: 47.9375em)": {
                    flexDirection: "column",
                  },
                }}
              >
                <InputBase
                  sx={{ ml: 2, flex: 1 }}
                  placeholder=""
                  value={userInfo?.insertion || ""}
                  onChange={(e) => handleChange("insertion", e.target.value)}
                />
              </Box>
            </Box>
          </Box>
          <Box
            sx={{
              display: "flex",
              gap: 4,
              justifyContent: "flex-start",
            }}
          >
            <Box
              sx={{
                "@media screen and (max-width: 47.9375em)": {
                  flex: 1,
                  // width:1
                },
                width:"150px"
              }}
            >
              <Box lineHeight={"38px"}>
                <Text light>{t("lastname")}</Text>
              </Box>
            </Box>
            <Box>
              <Box
                display="flex"
                height="32px"
                // maxWidth="400px"
                borderRadius="20px"
                backgroundColor={theme.palette.mode === "dark" ? colors.grey[300] : colors.grey[100]}
                sx={{
                  marginTop: 0.5,
                  marginBottom: 0.5,
                  justifyContent: "space-between",
                  "@media screen and (max-width: 47.9375em)": {
                    flexDirection: "column",
                  },
                }}
              >
                <InputBase
                  sx={{ ml: 2, flex: 1 }}
                  placeholder=""
                  value={userInfo?.lastname || ""}
                  onChange={(e) => handleChange("insertion", e.target.value)}
                />
              </Box>
            </Box>
          </Box>
          {/* </Box> */}
        </Dropdown>

        <Dropdown title={t("usergroup")} open>
          <Box
            sx={{
              display: "flex",
              gap: 4,
              justifyContent: "flex-start",
            }}
          >
            <Box
              sx={{
                lineHeight:"40px",
                "@media screen and (max-width: 47.9375em)": {
                  flex: 1,
                  // width:1
                },
                width:"250px"
              }}
            >
                <Text light>{t("general")}</Text>
            </Box>

            <InputDropdown
                displayName={"displayName"} 
                onChange={(selected) => handleChange("usergroupWarehouse#-1", selected)}
                noSelection={t("choose_usergroup")} //Custom text when nothing is selected
                options={usergroups ?? null}
                selected={usergroups?.findIndex(groups => groups.id === userInfo?.usergroups.find(usergroup => usergroup.warehouseId === -1)?.id) ?? -1}
                closeOnSelection
            />
          </Box>

          {warehouses?.data.map((warehouse,index) => (
            <Box
            key={index}
            sx={{
              display: "flex",
              gap: 4,
              justifyContent: "flex-start",
              mt:2,
              
            }}
          >
            <Box
              sx={{
                lineHeight:"40px",
                "@media screen and (max-width: 47.9375em)": {
                  flex: 1,
                  //width:"500px"
                  // width:1
                },
                width:"250px"
              }}
            >
                <Text light>{warehouse?.name}</Text>
              </Box>

            <InputDropdown
                displayName={"displayName"} 
                onChange={(selected) => handleChange(`usergroupWarehouse#${warehouse?.id}`, selected)}
                noSelection={t("choose_usergroup")} //Custom text when nothing is selected
                options={usergroups ?? null}
                selected={usergroups?.findIndex(groups => groups.id === userInfo?.usergroups?.find(usergroup => usergroup.warehouseId === warehouse.id)?.id) ?? -1}
                closeOnSelection
            />
          </Box>
          ))}
        </Dropdown>

        <Box
          sx={{
            position: "absolute",
            bottom: 0,
            right: 0,
            padding: 3,
            width: 1,
            background:
              theme.palette.mode === "dark"
                ? colors.grey[200]
                : colors.grey[0],
            "@media screen and (min-width: 47.9376em) and (max-width: 63.9375em)":
              {
                padding: 2,
                paddingY: 2.5,
              },
          }}
        >
          <MConfirmDelete
                open={confirmDeleteModal.open}
                handleClose={() => confirmDeleteModal.setOpen(false)}
                data={confirmDeleteModal.data}
                confirm={confirmDeleteModal.confirm}
            />
          {/* Pass the necessary state values and setter functions to GridActions */}
          <GridActions
            id={userInfo?.id}
            action={handleSaveClick}
            deleteAction={handleDeleteClick}
            // active={active}
            // blocked={blocked}
            // remark={remark}
            // carrier={carrier}
            // quarantine={quarantine}
            // enableLinkingWhenBlocked={enableLinkingWhenBlocked}
          />
        </Box>
      </Box>
    );
}

export default DetailsPanel;