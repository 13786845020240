import { Box, IconButton, InputBase, Switch, ToggleButton, ToggleButtonGroup } from "@mui/material";
import { DataGrid, GridToolbar, useGridApiRef } from "@mui/x-data-grid";
import { useTheme } from "@mui/material";
import ShDataGrid from "../../../../theme/datagrid/ShDataGrid";
import Inventory2Icon from '@mui/icons-material/Inventory2';
import { tokens } from "../../../../../theme";
import Shbutton from "../../../../theme/buttons/Shbutton";
import AddIcon from '@mui/icons-material/Add';
import WarehouseIcon from '@mui/icons-material/Warehouse';
import Icon from "../../../../theme/icons/Icon";
import Text from "../../../../theme/text/Text";

import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import Dropdown from "../../../../theme/dropout/DropOut";
import { useEffect, useState } from "react";
import cx from "classnames"
// import ButtonGroup from "../../../../theme/buttons/ButtonGroup";
import CheckOutlinedIcon from '@mui/icons-material/CheckOutlined';
import ButtonGroup from "../../../../theme/buttons/ButtonGroup";
import { useTranslation } from "react-i18next";

const AvailableStock = (props) => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const [data, setData] = useState([]);
    const [quantity, setQuantity] = useState(0);
    const [reserved, setReserved] = useState(0);
    const [available, setAvailable] = useState(0);
    const [blocked, setBlocked] = useState(0);
    const [transition, setTransition] = useState(0);
    const [quarantine, setQuarantine] = useState(0);
    const [pickingprocess, setPickingProcess] = useState(0);
    const [preRegistered, setPreRegistered] = useState(0);
    const  { t } = useTranslation();

    
    
    useEffect(() => {
        if(props.data) {
            setQuantity((props.data.totalStockQuantity !== null ? props.data.totalStockQuantity : 0));
            setReserved((props.data.totalStockReserved !== null ? props.data.totalStockReserved : 0));
            setAvailable((props.data.totalStockAvailable !== null ? props.data.totalStockAvailable : 0));
            setBlocked((props.data.totalStockBlocked !== null ? props.data.totalStockBlocked : 0));
            setTransition((props.data.totalStockTransition !== null ? props.data.totalStockTransition : 0));
            setQuarantine((props.data.totalStockQuarantine !== null ? props.data.totalStockQuarantine : 0));
            setPickingProcess((props.data.totalStockPickingProcess !== null ? props.data.totalStockPickingProcess : 0));
            setPreRegistered((props.data.preRegistered !== null ? props.data.preRegistered : 0));
        }
    },[props.isActive,props.data]);

    return (
        <Box height={1} display={"flex"} flexDirection={"column"} paddingX={3} overflow={"auto"}>
            <Dropdown title={t("available_stock")} alwaysOpen>
                <Box
                    sx={{
                        display:"flex",
                        alignItems:"center",
                        gap:4,
                        "@media screen and (max-width: 47.9375em)" : {
                            flexDirection:"column",
                            gap:1
                        }
                    }}
                >
                    <Box 
                        display={"flex"} 
                        flexDirection={"column"}
                        sx={{
                            // gap:1,
                            position:"relative",
                            
                            "@media screen and (max-width:47.9375em)" : {
                                width:1,
                                flexDirection:"row",
                                alignItems:"center",
                                justifyContent:"space-between",
                            }
                        }}
                        >
                        <Text light>
                            {t('all_stock')}
                        </Text>
                        <Box>
                            <Text bold>
                                {quantity}
                            </Text>
                
                            <Text italic>
                                &nbsp;(pcs)
                            </Text>
                        </Box>
                    </Box>
                    <Box
                        sx={{
                            "@media screen and (max-width: 47.9375em)" : {
                                display:"none"
                            }
                        }}
                    >
                        <Text light>-</Text>
                    </Box>

                    <Box 
                        display={"flex"} 
                        flexDirection={"column"}
                        sx={{
                            // gap:1,
                            position:"relative",
                            
                            "@media screen and (max-width:47.9375em)" : {
                                width:1,
                                flexDirection:"row",
                                alignItems:"center",
                                justifyContent:"space-between",
                            }
                        }}
                        >
                        <Text light>
                            {t('pickingprocess')}
                        </Text>
                        <Box>
                            <Text bold>
                                {pickingprocess ?? 0}
                            </Text>
                
                            <Text italic>
                                &nbsp;(pcs)
                            </Text>
                        </Box>
                    </Box>
                    <Box
                        sx={{
                            "@media screen and (max-width: 47.9375em)" : {
                                display:"none"
                            }
                        }}
                    >
                        <Text light>-</Text>
                    </Box>

                    <Box 
                        display={"flex"} 
                        flexDirection={"column"}
                        sx={{
                            // gap:1,
                            position:"relative",
                            
                            "@media screen and (max-width:47.9375em)" : {
                                width:1,
                                flexDirection:"row",
                                alignItems:"center",
                                justifyContent:"space-between",
                            }
                        }}
                        >
                        <Text light>
                            {t('blocked')}
                        </Text>
                        <Box>
                            <Text bold>
                                {blocked ?? 0}
                            </Text>
                
                            <Text italic>
                                &nbsp;(pcs)
                            </Text>
                        </Box>
                    </Box>
                    <Box
                        sx={{
                            "@media screen and (max-width: 47.9375em)" : {
                                display:"none"
                            }
                        }}
                    >
                        <Text light>-</Text>
                    </Box>

                    <Box 
                        display={"flex"} 
                        flexDirection={"column"}
                        sx={{
                            // gap:1,
                            position:"relative",
                            
                            "@media screen and (max-width:47.9375em)" : {
                                width:1,
                                flexDirection:"row",
                                alignItems:"center",
                                justifyContent:"space-between",
                            }
                        }}
                        >
                        <Text light>
                            {t('reserved')}
                        </Text>
                        <Box>
                            <Text bold>
                                {reserved ?? 0}
                            </Text>
                
                            <Text italic>
                                &nbsp;(pcs)
                            </Text>
                        </Box>
                    </Box>
                    <Box
                        sx={{
                            "@media screen and (max-width: 47.9375em)" : {
                                display:"none"
                            }
                        }}
                    >
                        <Text light>=</Text>
                    </Box>

                    <Box 
                        display={"flex"} 
                        flexDirection={"column"}
                        sx={{
                            // gap:1,
                            position:"relative",
                            
                            "@media screen and (max-width:47.9375em)" : {
                                width:1,
                                flexDirection:"row",
                                alignItems:"center",
                                justifyContent:"space-between",
                            }
                        }}
                        >
                        <Text light>
                            {t('available')}
                        </Text>
                        <Box>
                            <Text bold>
                                {available}
                            </Text>
                
                            <Text italic>
                                &nbsp;(pcs)
                            </Text>
                        </Box>
                    </Box>
                </Box>

            </Dropdown>

            <Dropdown isLast title={t("other_stock")} alwaysOpen>
                <Box
                    sx={{
                        display:"flex",
                        alignItems:"center",
                        gap:4,
                    }}
                >
                    <Box 
                        display={"flex"} 
                        flexDirection={"column"}
                        sx={{
                            // gap:1,
                            position:"relative",
                        }}
                        >
                        <Text light>
                            {t('quarantine')}
                        </Text>
                        <Box>
                            <Text bold>
                                {quarantine ?? 0}
                            </Text>
                
                            <Text italic>
                                &nbsp;(pcs)
                            </Text>
                        </Box>
                    </Box>
                    
                    <Box 
                        display={"flex"} 
                        flexDirection={"column"}
                        sx={{
                            // gap:1,
                            position:"relative",
                            
                        }}
                        >
                        <Text light>
                            {t('pre_registered')}
                        </Text>
                        <Box>
                            <Text bold>
                                {preRegistered ?? 0}
                            </Text>
                
                            <Text italic>
                                &nbsp;(pcs)
                            </Text>
                        </Box>
                    </Box>
                </Box>

            </Dropdown>
            
            <Box
                sx={{
                    position:"absolute",
                    bottom:0,
                    right:0,
                    padding:3,
                    width:1,
                    background:theme.palette.mode === "dark" ? colors.bg["tertiary"] : colors.grey[0],
                    "@media screen and (min-width: 47.9376em) and (max-width: 63.9375em)" : {
                        padding:2,
                        paddingY:2.5,
                    }
                }}
            >
            </Box>
        </Box>
    );
}

export default AvailableStock;