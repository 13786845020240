import { Box, Button, Divider, FormControl, IconButton, InputBase, InputLabel, Switch, TextField, ToggleButton, ToggleButtonGroup, Typography } from "@mui/material";
import { DataGrid, GridToolbar, useGridApiRef } from "@mui/x-data-grid";
import { useTheme } from "@mui/material";

import { tokens } from "../../../../../theme";
import Shbutton from "../../../../theme/buttons/Shbutton";
import SaveIcon from '@mui/icons-material/Save';

import Text from "../../../../theme/text/Text";

import Dropdown from "../../../../theme/dropout/DropOut";
import { useEffect, useState } from "react";

import NumberInput from "../../../../theme/inputs/NumberInput";

import CropFreeIcon from '@mui/icons-material/CropFree';
import ButtonGroup from "../../../../theme/buttons/ButtonGroup";
import { useEditStreamMutation, useGetPickingprocesstypesQuery } from "../../../../../newapi/global/stream/streamSlice";

import { useTranslation } from "react-i18next";
import { toast } from 'react-toastify';

import { showToastMessageRequestError, showToastMessageUpdateRequest } from "../../../../../utils/toasts";
import { InputDropdown } from "../../../../theme/dropdowns/InputDropdown";
import { useGetReachTypesSelectQuery } from "../../../../../newapi/warehouse/reachTypeSlice";
const GridActions = (props) => {
    const theme = useTheme();
    const { t } = useTranslation();

    return (
        <ButtonGroup version={2}>
            <Shbutton
                className={"group-btn"}
                onClick={props.action}
                color={theme.palette.mode === "dark" ? "blue" : "primary"}
                variant="contained"
                endIcon={<SaveIcon />}
            >
                {t('save')}
            </Shbutton>
        </ButtonGroup>
    );
};

const DetailsPanel = (props) => {
    const  { t } = useTranslation();
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    const [streamInfo, setStreamInfo] = useState({
      id: 0,
      name: "",
      shortName: "",
      minWeight: 0,
      minLngth: 0,
      minWidth: 0,
      minHeight: 0,
      minVolume: 0,
      maxWeight: 0,
      maxLength: 0,
      maxWidth: 0,
      maxHeight: 0,
      maxVolume: 0,
      deliverytimePriority: false,
      shipperId: 0,
      reachTypeId : 0,
      pickingprocesstypes: ""
    });

    const { data: pickingprocesstypes } = useGetPickingprocesstypesQuery();
    const { data: reachtypes } = useGetReachTypesSelectQuery();

    useEffect(() => {
        setStreamInfo(props.data);
    },[props.data]);
    

    const handleChange = (key, value) => {
        setStreamInfo({
          ...streamInfo,
          [key]: value
        });
      };

      
    useEffect(() => {
        props.replaceView({
            ...props.config,
            mobileActions:<GridActions data={streamInfo}></GridActions>,
        });

        return () => {
            // console.log("unmounting warehouse");
        }
    },[streamInfo]);

    // useEffect(() => {
    //     if (props) {
    //         props.addView({
    //             ...view,
    //         },true);
    //     } 
    // }, [props]);
    const [anchorEl, setAnchorEl] = useState(null);

    const handlePopoverOpen = (event) => {
        console.log("hello");
        setAnchorEl(event.currentTarget);
    };
  
    const handlePopoverClose = () => {
      setAnchorEl(null);
    };
    
    const [EditStream,{isLoading}] = useEditStreamMutation();
    const handleSaveClick = async () => {
        const canSave = true;
        if(canSave){    
            try {
                const data = await EditStream(streamInfo).unwrap()
                showToastMessageUpdateRequest(t, data);
            } catch (err) {
                showToastMessageRequestError(t("update_failed"), t, {success: false, error: err.error});
            }
            
        } else {
            if(!isLoading) {
                  showToastMessageRequestError(t("not_all_fields_filled"), t, {success: false, error: ""});
            }    
        }        
    };

    return (
        <Box height={1} display={"flex"} flexDirection={"column"} paddingX={3}
            sx={{
                overflow:'scroll',
                "@media screen and (min-width: 48.1em)"  :{
                    marginBottom:11
                }
            }}
        >
            <Dropdown title={t("basic")} open>
                <Box sx={{
                    display:"flex",
                    justifyContent:"space-between",
                    "@media screen and (max-width: 47.9375em)" : {
                        flexDirection:"column"
                    }
                }}>
                    
                    {/* Two columns */}
                    
                    <Box flex={4}
                        sx={{
                            display:"flex",
                            gap:4,
                            justifyContent:"flex-start"
                        }}
                    >
                        <Box
                            sx={{
                                "@media screen and (max-width: 47.9375em)" : {
                                    flex:1
                                }
                            }}
                        >
                            <Box lineHeight={"38px"}>
                                <Text>{t("name")}</Text>
                            </Box>
                        </Box>
                        <Box>
                            <Box display="flex"
                                height="32px"
                                // maxWidth="400px"
                                borderRadius="20px"
                                backgroundColor={colors.grey[100]}
                                sx={{
                                    marginTop:0.5,
                                    marginBottom:0.5,
                                    justifyContent:"space-between",
                                    "@media screen and (max-width: 47.9375em)" : {
                                        flexDirection:"column"
                                    }
                                }}
                            >
                                <InputBase sx={{ml: 2, flex:1}} placeholder="" value={streamInfo?.name} onChange={(e) => handleChange('name', e.target.value)}/>
                            </Box>
                            
                        </Box>
                    </Box>

                    <Box flex={4}
                        sx={{
                            display:"flex",
                            gap:4,
                            justifyContent:"flex-start"
                        }}
                    > 
                        <Box
                            sx={{
                                "@media screen and (max-width: 47.9375em)" : {
                                    flex:1,
                                    // width:1
                                }
                            }}
                        >
                            <Box lineHeight={"38px"}>
                                <Text>{t("short_name")}</Text>
                            </Box>
                            
                        </Box>
                        <Box>
                            <Box display="flex"
                                height="32px"
                            // maxWidth="400px"
                                borderRadius="20px"
                                backgroundColor={colors.grey[100]}
                                sx={{
                                    marginTop:0.5,
                                    marginBottom:0.5,
                                    justifyContent:"space-between",
                                    "@media screen and (max-width: 47.9375em)" : {
                                        flexDirection:"column"
                                    }
                                }}
                            >
                                <InputBase sx={{ml: 2, flex:1}} placeholder="" value={streamInfo?.shortName} onChange={(e) => handleChange('shortName', e.target.value)}/>
                            </Box>
                            
                        </Box>
                    </Box>
                    
                </Box>
                <Box 
        display={"flex"} 
        flexDirection={"column"}
        sx={{
            //maxWidth: 160,
            width:1,
            paddingTop:3,
            "@media screen and (max-width:47.9375em)" : {
                width:1,
                flexDirection:"row",
                gap:4,
                alignItems:"center",
                justifyContent:"space-between",
            }
        }}
        >
            <Box
                sx={{
                    display:"flex",
                    alignItems:"center",
                    mb:0.5,
                    "@media screen and (max-width:47.9375em)" : {
                        mb:0
                    }
                }}
            >
                <Text semiBold>
                    {t('reachtype')}
                </Text>
                
            </Box>
            <InputDropdown
            displayName={"description"} 
            onChange={(selected) => handleChange('reachTypeId', reachtypes.data[selected].id)}
            noSelection={t("choose_reachtype")} //Custom text when nothing is selected
            options={reachtypes?.data ?? null}
            selected={reachtypes?.data.findIndex(reachtype => reachtype.id === streamInfo?.reachTypeId) ?? 0}
            closeOnSelection
        />
    </Box>
    <Box 
        display={"flex"} 
        flexDirection={"column"}
        sx={{
            //maxWidth: 160,
            width:1,
            paddingTop:3,
            "@media screen and (max-width:47.9375em)" : {
                width:1,
                flexDirection:"row",
                gap:4,
                alignItems:"center",
                justifyContent:"space-between",
            }
        }}
        >
            <Box
                sx={{
                    display:"flex",
                    alignItems:"center",
                    mb:0.5,
                    "@media screen and (max-width:47.9375em)" : {
                        mb:0
                    }
                }}
            >
                <Text semiBold>
                    {t('pickingprocesstype')}
                </Text>
                {/*  */}
                {/* <EMDef */}
                
            </Box>
            <InputDropdown
            displayName={"name"} 
            onChange={(selected) => handleChange('pickingprocesstype', pickingprocesstypes[selected])}
            noSelection={t("choose_type")} //Custom text when nothing is selected
            options={pickingprocesstypes ?? null}
            selected={pickingprocesstypes?.findIndex(pickingprocesstype => pickingprocesstype === streamInfo?.pickingprocesstype) ?? "auto"}
            closeOnSelection
        />
    </Box>
            </Dropdown>
            <Dropdown title={t("measurements")} subTitle={t("stream_min_max_dimensions_and_weight_description")} open={window.innerWidth > 767} isLast sx={{paddingBottom:"0px"}}>
            {/* <Divider></Divider> */}
                <Box sx={{
                    display:"flex",
                    flexDirection:"column"
                }}>
                    
                    <Box 
                        sx={{
                            width:1,
                            display:"flex",
                            alignItems:"center",
                            justifyContent:"space-between",
                            gap:7
                        }}
                    >

                    <Box
                        sx={{
                            width:88,
                        }}
                    >

                    </Box>
            
                    <Box    
                        sx={{
                                flex:1,
                                display:"flex",
                                justifyContent:"space-between",
                                alignItems:"center"
                            }}
                    >
                        <Box   
                            sx={{
                                flex:1,
                                display:"flex",
                                justifyContent: "center"
                            }}
                        >
                            <Text bold>{t("min")}</Text>
                        </Box>
                        <Box   
                            sx={{
                            flex:1,
                            display:"flex",
                            justifyContent: "center"
                            }}
                        >
                            <Text bold>{t("max")}</Text>
                        </Box>
                    </Box>
                </Box>
    <Box 
        sx={{
            width:1,
            paddingTop: 2,
            display:"flex",
            alignItems:"center",
            justifyContent:"space-between",
            gap:7
        }}
    >

        <Box
            sx={{
                width:88,
            }}
        >
            <Text>{t("weight")}</Text>&nbsp;
            <Text italic light>(kg)</Text>
        </Box>
        
        <Box sx={{
                display:"flex",
                flex:1
            }}>
        <Box    
            sx={{
                flex:1,
                backgroundColor : `${theme.palette.mode === "dark" ?colors.grey[300] : colors.grey[100]}`,
                borderRadius:4,
                display:"flex",
                height:32,
                gap: 1
            }}
        >
            <NumberInput btnSize={"24"}
            value={streamInfo?.minWeight ?? 0}
            change={(e) => handleChange('minWeight', e)}/>
        </Box>
        <Box    
            sx={{
                flex:1,
                backgroundColor : `${theme.palette.mode === "dark" ?colors.grey[300] : colors.grey[100]}`,
                borderRadius:4,
                display:"flex",
                height:32,
                gap: 1
            }}
        >
            <NumberInput btnSize={"24"}
            value={streamInfo?.maxWeight ?? 0}
            change={(e) => handleChange('maxWeight', e)}/>
        </Box>
        </Box>
    </Box>
    <Box 
        sx={{
            width:1,
            paddingTop: 2,
            display:"flex",
            alignItems:"center",
            justifyContent:"space-between",
            gap:7
        }}
    >

        <Box
            sx={{
                width:88,
            }}
        >
            <Text>{t("length")}</Text>&nbsp;
            <Text italic light>(cm)</Text>
        </Box>
        
        <Box sx={{
                display:"flex",
                flex:1,
            }}>
        <Box    
            sx={{
                flex:1,
                backgroundColor : `${theme.palette.mode === "dark" ?colors.grey[300] : colors.grey[100]}`,
                borderRadius:4,
                display:"flex",
                height:32,
                gap: 1
            }}
        >
            <NumberInput btnSize={"24"}
            value={streamInfo?.minLength ?? 0}
            change={(e) => handleChange('minLength', e)}/>
        </Box>
        <Box    
            sx={{
                flex:1,
                backgroundColor : `${theme.palette.mode === "dark" ?colors.grey[300] : colors.grey[100]}`,
                borderRadius:4,
                display:"flex",
                height:32,
                gap: 1
            }}
        >
            <NumberInput btnSize={"24"}
            value={streamInfo?.maxLength ?? 0}
            change={(e) => handleChange('maxLength', e)}/>
        </Box>
        </Box>
    </Box>
    <Box 
        sx={{
            width:1,
            paddingTop: 2,
            display:"flex",
            alignItems:"center",
            justifyContent:"space-between",
            gap:7
        }}
    >

        <Box
            sx={{
                width:88,
            }}
        >
            <Text>{t("width")}</Text>&nbsp;
            <Text italic light>(cm)</Text>
        </Box>
        
        <Box sx={{
                display:"flex",
                flex:1,
            }}>
        <Box    
            sx={{
                flex:1,
                backgroundColor : `${theme.palette.mode === "dark" ?colors.grey[300] : colors.grey[100]}`,
                borderRadius:4,
                display:"flex",
                height:32,
                gap: 1
            }}
        >
            <NumberInput btnSize={"24"}
            value={streamInfo?.minWidth ?? 0}
            change={(e) => handleChange('minWidth', e)}/>
        </Box>
        <Box    
            sx={{
                flex:1,
                backgroundColor : `${theme.palette.mode === "dark" ?colors.grey[300] : colors.grey[100]}`,
                borderRadius:4,
                display:"flex",
                height:32,
                gap: 1
            }}
        >
            <NumberInput btnSize={"24"}
            value={streamInfo?.maxWidth ?? 0}
            change={(e) => handleChange('maxWidth', e)}/>
        </Box>
        </Box>
    </Box>
    <Box 
        sx={{
            width:1,
            paddingTop: 2,
            display:"flex",
            alignItems:"center",
            justifyContent:"space-between",
            gap:7
        }}
    >

        <Box
            sx={{
                width:88,
            }}
        >
            <Text>{t("height")}</Text>&nbsp;
            <Text italic light>(cm)</Text>
        </Box>
        
        <Box sx={{
                display:"flex",
                flex:1,
            }}>
        <Box    
            sx={{
                flex:1,
                backgroundColor : `${theme.palette.mode === "dark" ?colors.grey[300] : colors.grey[100]}`,
                borderRadius:4,
                display:"flex",
                height:32,
                gap: 1
            }}
        >
            <NumberInput btnSize={"24"}
            value={streamInfo?.minHeight ?? 0}
            change={(e) => handleChange('minHeight', e)}/>
        </Box>
        <Box    
            sx={{
                flex:1,
                backgroundColor : `${theme.palette.mode === "dark" ?colors.grey[300] : colors.grey[100]}`,
                borderRadius:4,
                display:"flex",
                height:32,
                gap: 1
            }}
        >
            <NumberInput btnSize={"24"}
            value={streamInfo?.maxHeight ?? 0}
            change={(e) => handleChange('maxHeight', e)}/>
        </Box>
        </Box>
    </Box>
    <Box 
        sx={{
            width:1,
            paddingTop: 2,
            display:"flex",
            alignItems:"center",
            justifyContent:"space-between",
            gap:7
        }}
    >

        <Box
            sx={{
                width:88,
            }}
        >
            <Text>{t("volume")}</Text>&nbsp;
            <Text italic light>(cm3)</Text>
        </Box>
        
        <Box sx={{
                display:"flex",
                flex:1,
            }}>
        <Box    
            sx={{
                flex:1,
                backgroundColor : `${theme.palette.mode === "dark" ?colors.grey[300] : colors.grey[100]}`,
                borderRadius:4,
                display:"flex",
                height:32,
                gap: 1
            }}
        >
            <NumberInput btnSize={"24"}
            value={streamInfo?.minVolume ?? 0}
            change={(e) => handleChange('minVolume', e)}/>
        </Box>
        <Box    
            sx={{
                flex:1,
                backgroundColor : `${theme.palette.mode === "dark" ?colors.grey[300] : colors.grey[100]}`,
                borderRadius:4,
                display:"flex",
                height:32,
                gap: 1
            }}
        >
            <NumberInput btnSize={"24"}
            value={streamInfo?.maxVolume ?? 0}
            change={(e) => handleChange('maxVolume', e)}/>
        </Box>
        </Box>
    </Box>
                    
                </Box>
            </Dropdown>

            <Box
                sx={{
                    position:"absolute",
                    bottom:0,
                    right:0,
                    padding:3,
                    width:1,
                    background:theme.palette.mode === "dark" ? colors.bg["tertiary"] : colors.grey[0],
                    "@media screen and (min-width: 47.9376em) and (max-width: 63.9375em)" : {
                        padding:2,
                        paddingY:2.5,
                    },
                    "@media screen and (max-width: 47.9375em)" : {
                        display:"none"
                    }
                }}
            >
                    {/* Pass the necessary state values and setter functions to GridActions */}
                <GridActions
                    id={streamInfo?.id}
                    action={handleSaveClick}
                />
            </Box>
        </Box>
    );
}

export default DetailsPanel;