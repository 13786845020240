import { Box } from "@mui/material";
import { DataGrid, GridToolbar, useGridApiRef } from "@mui/x-data-grid";
import { useTheme } from "@mui/material";
import Shbutton from "../../../../components/theme/buttons/Shbutton";
import ShDataGrid from "../../../../components/theme/datagrid/ShDataGrid";
import Inventory2Icon from '@mui/icons-material/Inventory2';
import { tokens } from "../../../../theme"
import AddIcon from '@mui/icons-material/Add';
import WarehouseIcon from '@mui/icons-material/Warehouse';
import Icon from "../../../../components/theme/icons/Icon";
import Text from "../../../../components/theme/text/Text";

import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import ShDateRangePicker from "../../../../components/theme/ShDateRangePicker";
import { useTranslation } from 'react-i18next';
import ShLabel from "../../../../components/theme/label/ShLabel";
import { useEffect, useRef, useState } from "react";
import { useGetOrderQuery, useGetOrdersQuery } from "../../../../newapi/order/ordersSlice";
import OrdersCard from "../../../../components/theme/cards/orders/OrdersCard";
import ButtonGroup from "../../../../components/theme/buttons/ButtonGroup";
import ShLabelSkeleton from "../../../../components/theme/skeleton/ShLabelSkeleton";
import ShTextSkeleton from "../../../../components/theme/skeleton/ShTextSkeleton";

import { useNavigate } from 'react-router-dom';
import { checkUserPermission, UserPrivileges, UNAUTHORIZED_ENDPOINT } from '../../../../utils/permissions';
import SpOrderDetails from "../../../../components/global/Sidepanels/SpOrderDetails/SpOrderDetails";
import { useSelector } from "react-redux";
import ShDataGrid2 from "../../../../components/theme/datagrid2/ShDataGrid2";

const GridActions = (props) => {
    const theme = useTheme();
    return (
        <Box display="flex" alignItems="center" justifyContent="space-between" flex={1} gap={2}>
            <ButtonGroup version={2}>
                <Shbutton className={"group-btn"} color={theme.palette.mode === "dark" ? "blue" : "primary"} variant="contained">New</Shbutton>
            </ButtonGroup>
        </Box>
    )
}

let gridOptions = {
    export:true,
    disableCustomActionsOnPhone: true,
    isSidepanel: true,
    filter : {
        name: "orderFilter",
        selectedFilter : null,
        groups : [
            {
                conditionExtensionType : 0,
                rules : [
                ]
            }
        ]
    }
};

const OutboundPriorityView = (props) => {
    // Check if user has permission to access the page
   const navigate = useNavigate();
   useEffect(() => {
       if (!checkUserPermission([UserPrivileges.OUTBOUND_READ])) {
         navigate(UNAUTHORIZED_ENDPOINT); // Use navigate instead of history.push
       }
     }, [navigate]);

    const { t } = useTranslation();
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const [data, setData] = useState([]);
    const [config, setConfig] = useState({"priority":["highcare", "runner", "special"], "relationId": useSelector((state) => state.settings.environment.selectedId)});
    const [isExtended, setIsExtended] = useState(false);
    const [selectedRowId, setSelectedRowId] = useState(-1);
    const [rowSelected, setRowSelected] = useState(false);

    useEffect(() => {
        props.replaceView({
            ...props.config,
            gridOptions: gridOptions,
            mobileActions: <GridActions />,
        });

        return () => {
            
        }
    },[]);

    const [orderId, setOrderId] = useState();
    const orderIdRef = useRef(orderId);
    
    const getOrderDetails = (params) => {
        if (!params) return;
    
        const neworderId = parseInt(params.row.id);
        // Only update state if the orderId has actually changed
        if (orderIdRef.current !== neworderId) {
            setOrderId(neworderId);
            orderIdRef.current = neworderId; // Update the ref to the new value
        }
        setIsExtended(true);
    };


    const columns = [
        {
            field: "id",
            headerName: "Order",
            shFilter : {
                type: "number",
            },
            renderCell: (params) => {

                if(params.row.skeleton) {
                    return <ShTextSkeleton/>
                }

                return params.row.id;
            }
        },
        // 'deliveryName',
        // 'date_planned',
        // 'date_sent'
        // [sequelize.col('shipper.name'), 'shipperName'],
        // 'pickup_type',
        // 'backorder'
        {field: "webshopreference", headerName: t("webshop"),flex:1,
            renderCell: (params) => {

                if(params.row.skeleton) {
                    return <ShTextSkeleton/>
                }

                return params.row.webshopreference;
            }
        },
        {field: "webshopcredentials", headerName: t("channel_name"),flex:1,
            renderCell: (params) => {

                if(params.row.skeleton) {
                    return <ShTextSkeleton/>
                }

                return (<Text>{params.row.webshopcredentials.description}</Text>)
            }
        },
        {field: "deliveryName", headerName: t("delivery_name"),flex:1,
            renderCell: (params) => {

                if(params.row.skeleton) {
                    return <ShTextSkeleton/>
                }

                return params.row.deliveryName;
            }
        },
        {
            field: "priority", 
            headerName: t("priority"),
            flex:1,
            renderCell: (params) => {

                if(params.row.skeleton) {
                    return <ShTextSkeleton/>
                }

                let status = params.row.priority;
               
                let labeltext = "";
                switch(status){
                    case "normal":
                        labeltext = t('prio_normal');
                        break;
                    case "highcare":
                        labeltext = t('prio_highcare');
                        break;
                    case "runner":
                        labeltext = t('prio_runner');
                        break;
                    case "special":
                        labeltext = t('prio_special');
                        break;
                    default:
                        labeltext = t('prio_normal');
                        break;
                }
              
                return (
                    <Text size={32} fitted palette="normal">
                      {labeltext}
                    </Text>
                  );
              }
        },
        {
            field: "date_planned", 
            headerName: t("date_planned_shipment"),
            flex:1,
            shFilter: {
                type: "date"
            },
            renderCell: (params) => {
                if(params.row.skeleton) {
                    return <ShLabelSkeleton/>
                }
                let labelcolor = "grey";
                let planned = new Date(params.row.date_planned);
                let sent = params.row.date_sent ? new Date(params.row.date_sent) : null;
              
                if(sent) {
                    // when sent is filled and is equal to planned: green
                    if(sent.getTime() === planned.getTime()) {
                        labelcolor = "green";
                    }
                    // when sent is filled but later than planned: orange
                    else if(sent.getTime() > planned.getTime()) {
                        labelcolor = "orange";
                    }
                }
                else {
                    // When sent is not filled and planned >= now: blue
                    if(planned.getTime() >= Date.now()) {
                        labelcolor = "blue";
                    }
                    // When sent is not filled and planned < now: red
                    else if(planned.getTime() < Date.now()) {
                        labelcolor = "red";
                    }
                }
              
                return (
                    <ShLabel size={32} fitted variant={labelcolor} palette="normal">
                        {!sent && planned.toLocaleDateString(t.language)}
                        {sent && sent.toLocaleDateString(t.language)}
                    </ShLabel>
                );
            }
        },
        {field: "size", headerName: t("order_size"),flex:1,
            renderCell: (params) => {

                if(params.row.skeleton) {
                    return <ShTextSkeleton/>
                }

                return params.row.size;
            }},
        {field: "orderlinesCount", headerName: t("orderlines_count"),flex:1,
        renderCell: (params) => {

            if(params.row.skeleton) {
                return <ShTextSkeleton/>
            }

            return params.row.orderlinesCount;
        }},
        {field: "shipperName", headerName: t("shipper_name"),flex:1,
        renderCell: (params) => {

            if(params.row.skeleton) {
                return <ShTextSkeleton/>
            }

            return params.row.shipperName;
        }},
        {field: "pickup_type", headerName: t("pickup_type"),flex:1,
        renderCell: (params) => {

            if(params.row.skeleton) {
                return <ShTextSkeleton/>
            }

            return params.row.pickup_type;
        }},
        {
            field: "state", 
            headerName: t("state"),
            flex:1,
            shFilter: {
                type: "date"
            },
            renderCell: (params) => {

                if(params.row.skeleton) {
                    return <ShLabelSkeleton/>
                }

                let status = params.row.state;
                let labelcolor = "";
                let labeltext = "";
                switch(status){
                    case "blocked":
                        labelcolor = "red"
                        labeltext = t('blocked');
                        break;
                    case "replenish":
                        labelcolor = "purple"
                        labeltext = t('replenish');
                        break;
                    case "backordered":
                        labelcolor = "pink"
                        labeltext = t('backordered');
                        break;
                    default:
                        labelcolor = "orange"
                        labeltext = t('unknown');
                        break;
                }
                return (
                    <ShLabel size={32} fitted variant={labelcolor} palette="normal">
                      {labeltext}
                    </ShLabel>
                  );
              }
        },
        {
            field: "phoneView", 
            headerName: "PhoneView",
            cellClassName: "phone-column--cell",
            renderCell: (params) => {
                return (
                    <OrdersCard skeleton={params.row.skeleton ?? false} {...params.row}  />
                )
            }
        }
    ];
    

    return (
        <Box height={1} display={"flex"} flexDirection={"column"}>
            {/* <GridActions></GridActions> */}
            <ShDataGrid2 key={theme.palette.mode}
                content={useGetOrdersQuery}
                config={config}
                gridActions={<GridActions></GridActions>} 
                title={t('priority') }
                gridOptions={gridOptions} 
                isSidepanelGrid 
                columns={columns}
                onRowClick={getOrderDetails}>
            </ShDataGrid2>
            <SpOrderDetails
                isExtended={isExtended}
                setIsExtended={setIsExtended}
                orderId={orderId}
                // data={orderData}
                relation={true}
                canReserveOrderLines={true}
            ></SpOrderDetails>
        </Box>
    );
}


export default OutboundPriorityView;