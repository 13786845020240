import { Box } from "@mui/material";
import { useTheme } from "@mui/material";
import ShDataGrid from "../../../../theme/datagrid/ShDataGrid";
import { tokens } from "../../../../../theme";
import Shbutton from "../../../../theme/buttons/Shbutton";
import ShLabel from "../../../../theme/label/ShLabel";
import AddIcon from '@mui/icons-material/Add';
import Text from "../../../../theme/text/Text";

import { useEffect, useState } from "react";
import CropFreeIcon from '@mui/icons-material/CropFree';
import ButtonGroup from "../../../../theme/buttons/ButtonGroup";

import ShoppingCartCheckoutIcon from '@mui/icons-material/ShoppingCartCheckout';
import OrderLinesCard from "../../../../theme/cards/orders/OrderLinesCard";
import {useTranslation} from "react-i18next";
import { useGetOrderLinesQuery } from "../../../../../newapi/order/ordersSlice";
import ShTextSkeleton from "../../../../theme/skeleton/ShTextSkeleton";
import { useReserverOrderLineMutation } from "../../../../../newapi/order/ordersSlice";
import { showToastMessageUpdateRequest, showToastMessageRequestError } from "../../../../../utils/toasts";
import ShDataGrid2 from "../../../../theme/datagrid2/ShDataGrid2";

const GridActions = (props) => {
    const theme = useTheme();
    const {t} = useTranslation();
    return (
        <ButtonGroup version={2}>
            <Shbutton className={"group-btn"} color={theme.palette.mode === "dark" ? "blue" : "primary"} variant="contained" endIcon={<AddIcon/>}>{t("save")}</Shbutton>
        </ButtonGroup>
    )
}


const gridOptions = {
    export:true,
    disableCustomActionsOnPhone: true,
    isSidepanel: true,
    hide:true,
    
    filter : {
        name: "stockFilter",
        selectedFilter : null,
        groups : [
            {
                conditionExtensionType : 0,
                rules : [
                ]
            }
        ]
    }
};

const OrderLines = (props) => {
    const {t} = useTranslation();
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);


    useEffect(() => {
        props.replaceView({
            ...props.config,
            gridOptions:gridOptions,
        });

        return () => {
            // console.log("unmounting warehouse");
        }
    },[]);
    

    const [ReserveOrderLine,{syncIsLoading}] = useReserverOrderLineMutation();
    const handleReserveOrderLine = async (params) => {
        try {
            const response = await ReserveOrderLine(params).unwrap();
            showToastMessageUpdateRequest(t, response);
        } catch (err) {
            showToastMessageRequestError(t("reserve_failed"), t, {success: false, error: err.error});
        }
    };

    const columns = [
        {field: "amount", headerName: t("quantity"), renderCell : (params) => {
            if(params.row.skeleton) {
                return <ShTextSkeleton/>
            }
            return (
                <Box paddingLeft={0}>
                    <Text semibold>{params.row.amount}</Text>
                </Box>
            )
        }},
        {field: "product.description", headerName: t("description"), flex:1, renderCell : (params) => {
            if(params.row.skeleton) {
                return <ShTextSkeleton/>
            }
            return (
                <Box>
                    <Text semibold>{params.row.product.description}</Text>
                </Box>
            )
        }},
        {field: "product.sku", headerName: t("sku"),flex:1,renderCell : (params) => {
            if(params.row.skeleton) {
                return <ShTextSkeleton/>
            }
            return (
                <Box>
                    <Text semibold variant={theme.palette.mode === "dark" ? "blue" : "primary"}>{params.row.product.sku}</Text>
                </Box>
            )
        }},
        
        {field: "product.barcode", headerName: t("barcode"), flex:1,
            renderCell : (params) => {
                if(params.row.skeleton) {
                    return <ShTextSkeleton/>
                }
                return params.value;
            }
        }
    ];

    if (props.canReserveOrderLines) {
        columns.push({
            field: "actions", 
            headerName: "",
            flex:1,
            renderCell : (params) => {
                if(params.row.skeleton) {
                    return <ShTextSkeleton/>
                }
                if (params.row.reserved) {
                    return <ShLabel size={32} palette="normal" variant={"blue"}>
                    {t('reserved')}
                  </ShLabel>;
                }

                return <Shbutton 
                    className={"s32"}
                    icon 
                    variant="contained" 
                    color="primaryGrey" 
                    type="button"
                    onClick={() => handleReserveOrderLine(params.row)}
                >
                    <ShoppingCartCheckoutIcon fontSize="small" />
                </Shbutton>;
            }
        });
    }

    columns.push({
        field: "phoneView", 
        headerName: "phoneView",
        cellClassName: "phone-column--cell",
        renderCell: (params) => {
            return (
                <OrderLinesCard skeleton={params.row.skeleton ?? false} {...params}/>
            )
        }
    });
    
    return (
        <Box height={1} display={"flex"} flexDirection={"column"} overflow={"auto"}>
            <ShDataGrid2 key={theme.palette.mode}
                content={useGetOrderLinesQuery}
                itemId={props.data}
                title={t("orderlines") }
                gridOptions={gridOptions} 
                isSidepanelGrid 
                columns={columns}>
            </ShDataGrid2>
        </Box>
    );
}

export default OrderLines;