import { Box, Button, IconButton, useTheme } from "@mui/material";
import { forwardRef, useEffect, useImperativeHandle, useRef, useState } from "react";
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
// import { tokens } from "../../../../../theme";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { tokens } from "../../../../../theme"; 
import Inventory2OutlinedIcon from '@mui/icons-material/Inventory2Outlined';
import ShDataGrid from "../../../../../components/theme/datagrid/ShDataGrid";
import Subheading from "../../../../../components/theme/text/Subheading";
import Text from "../../../../../components/theme/text/Text";

import AddIcon from '@mui/icons-material/Add';
import ButtonGroup from "../../../../../components/theme/buttons/ButtonGroup";
import Shbutton from "../../../../../components/theme/buttons/Shbutton";

import CheckOutlinedIcon from '@mui/icons-material/CheckOutlined';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import HorizontalRuleOutlinedIcon from '@mui/icons-material/HorizontalRuleOutlined';

import AllCarriersCard from "../../../../../components/theme/cards/carriers/AllCarriersCard";
import SpCarrierDetails from "../../../../../components/global/Sidepanels/SpCarrierDetails/SpCarrierDetails";
import { useTranslation } from "react-i18next";
import MAddCarrier from "../../../../../components/global/Modals/carriers/AddCarrier";
import {useGetUsersQuery, useGetUserQuery} from "../../../../../newapi/user/userSlice"
import MpAddUser from "../../../../../components/global/ModalsProgression/MpAddUser/MpAddUser";
import SpUserDetails from "../../../../../components/global/Sidepanels/SpUserDetails/SpUserDetails";
import SpUserPermissionDetails from "../../../../../components/global/Sidepanels/SpUserPermissionDetails/SpUserPermissionDetails";
import ShTextSkeleton from "../../../../../components/theme/skeleton/ShTextSkeleton";
import UserCard from "../../../../../components/theme/cards/settings/UserCard";
import ShDataGrid2 from "../../../../../components/theme/datagrid2/ShDataGrid2";
import { useSelector } from "react-redux";
// import MpAddShipper from "../../../../../components/global/ModalsProgression/MpAddShipper/MpAddShipper";
// import SpShipperDetails from "../../../../../components/global/Sidepanels/SpShipperDetails/SpShipperDetails";


const GridActions = (props, i18n) => {
    const theme = useTheme();
    useEffect(() => {

    },[props.open]);
    const {t} = useTranslation();
    return (      
        <ButtonGroup version={2}>
            <Shbutton 
                onClick={() => props.handleOpen(true)}
                className={"group-btn"} 
                color={theme.palette.mode === "dark" ? "blue" : "primary"} 
                variant="contained" 
                endIcon={<AddIcon/>}>{t("add_user")}</Shbutton>
        </ButtonGroup>
    )
}

const WareHouseDropdown = (props) => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    return(
        <Box
            sx={{
                display:"flex",
                alignItems:"center",
                justifyContent:"center",
                gap:2,
                height:40,
                background:theme.palette.mode === "dark" ? colors.grey["400"] : colors.txt["primary"],
                borderRadius:5,
                paddingX:2,
                paddingRight:1,
                borderTopRightRadius:0,
                borderBottomRightRadius:0,
                color:"#fff"
            }}
            onClick={() => props.setOpen(true)}
        >
            <Box>
                <Text bold variant="blue">t("all")</Text>&nbsp;<Text variant="white">t("warehouses")</Text>
            </Box>
            <KeyboardArrowDownIcon/>
        </Box>
    )
}


const gridOptions = {
    export:true,
    disableCustomActionsOnPhone: true,
    isSidepanel: true,
    
    filter : {
        name: "stockFilter",
        selectedFilter : null,
        groups : [
            {
                conditionExtensionType : 0,
                rules : [
                ]
            }
        ]
    }
};


const All = forwardRef((props, ref) => {
    const { t } = useTranslation();
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const [data, setData] = useState([]);
    const [isExtended,setIsExtended] = useState(false);
    const [isPermissionExtended,setIsPermissionExtended] = useState(false);
    const customer_id = useSelector((state) => state.settings.environment.selectedId);
    // const { getCarrier} = useGetCarrierQuery();
    // const [selectedRowId, setSelectedRowId] = useState(1);
    // const [rowSelected, setRowSelected] = useState(false);
    // const { data: userData, error, isLoading } = useGetUserQuery(
    //    selectedRowId,
    //     {
    //         skip: !rowSelected
    //     }
    // );
    


    const [addModalOpen, setAddModalOpen] = useState(false);
    const addModal = {
        open: addModalOpen,
        setOpen: setAddModalOpen
    }

    const handleClose = () => {
        setAddModalOpen(false);
      };

    const [userId, setUserId] = useState();
    const userIdRef = useRef(userId);
    
    const getUserDetails = (params) => {
        if (!params) return;
    
        const newuserId = parseInt(params.row.id);
        // Only update state if the userId has actually changed
        if (userIdRef.current !== newuserId) {
            setUserId(newuserId);
            userIdRef.current = newuserId; // Update the ref to the new value
        }
        setIsExtended(true);
    };
      
    // const getUserDetails = (row) => {  
    //     if (!row) return;
    //     setSelectedRowId(row.id);
    //     setRowSelected(true);
    // };

    // useEffect(() => {
    //     if (!isLoading && userData && rowSelected) {
    //         setIsExtended(true);
    //     }
    // }, [userData, isLoading, rowSelected]);

    // useEffect(() => {
    //     if(!isExtended) {
    //         setRowSelected(false);
    //     }
    // },[isExtended])
    
    useEffect(() => {
        props.replaceView({
            ...props.config,
            gridOptions:gridOptions,
            topRight:<WareHouseDropdown {...props.warehouseModal}/>,
            mobileActions:<GridActions handleOpen={() => setAddModalOpen(true)}></GridActions>
        });

        return () => {
            // console.log("unmounting warehouse");
        }
    },[]);

    
   
    
    const columns = [
        {field: "employee_code", headerName: t("employee") , flex:2, cellClassName: "user", renderCell : (params) => {
            if(params.row.skeleton) {
                return <ShTextSkeleton/>
            }
            return (
                <Text semibold variant={theme.palette.mode === "dark" ? "blue" : "primary"}>{params.value}</Text>
            )
        }},
        {field: "firstname", headerName: t("firstname") , flex:2, cellClassName: "user", renderCell : (params) => {
            if(params.row.skeleton) {
                return <ShTextSkeleton/>
            }
            return params.value
            }   
        },
        {field: "insertion", headerName: t("insertion") , flex:2, cellClassName: "user", renderCell : (params) => {
            if(params.row.skeleton) {
                return <ShTextSkeleton/>
            }
            return params.value
            }   
        },

        {field: "lastname", headerName: t("lastname") , flex:2, cellClassName: "user", renderCell : (params) => {
            if(params.row.skeleton) {
                return <ShTextSkeleton/>
            }
            return params.value
            }   
        },
        {field: "email", headerName: t("emailaddress") , flex:2, cellClassName: "user", 
        renderCell : (params) => {
            if(params.row.skeleton) {
                return <ShTextSkeleton/>
            }
            return params.value
            }   
        },
        // {field: "fitsPost", headerName: "Fits in dutch postorder", flex:1, renderCell : (cellValues) => {
        //     return (
        //         cellValues.value === "true" ?
        //             <Text variant="green"><CheckOutlinedIcon fontSize="small"/></Text>:
        //         cellValues.value === "false" ?
        //             <Text variant="red"><CloseOutlinedIcon fontSize="Small" /></Text> 
        //         :
        //             <Text variant="orange"><HorizontalRuleOutlinedIcon fontSize="Small"/></Text>
        //     )
        // }},

        // {field: "channels", headerName: "Channels", flex:1, renderCell : (cellValues) => {
        //     return (
        //         <Text semibold variant={theme.palette.mode === "dark" ? "blue" : "primary"}>{cellValues.value}</Text>
        //     )
        // }},
        // {field: "weight", headerName: "Weight including label", flex:1, renderCell : (cellValues) => {
        //     return (
        //         <Text>{cellValues.value}</Text>
        //     )
        // }},
        // {field: "length", headerName: t("dimensions_lwh"), flex:1, renderCell : (cellValues) => {
        //     return (
        //         <Text>{`${cellValues.row.length} x ${cellValues.row.width} x ${cellValues.row.height}`}</Text>
        //     )
        // }},
        // {field: "barcodes", headerName: "Nr. of barcodes", flex:1, renderCell : (cellValues) => {
        //     return (
        //         <Text>{cellValues.value}</Text>
        //     )
        // }},
        {
            field: "phoneView", 
            headerName: "PhoneView",
            cellClassName: "phone-column--cell",
            renderCell: (cellValues) => {
                // return "hi";
                return (
                    <UserCard skeleton={cellValues.row.skeleton ?? false} {...cellValues.row}/>
                );
                // console.log(cellValues.row);
               // return (
                    // <AllCarriersCard {...cellValues.row} />
               // )
            }
        }
    ];

    useImperativeHandle(ref, () => ({
    }));

    const handleRowClick = (params) => {
        setIsExtended(true);
    };
    
    return (
        props.isActive ?
            <Box
                sx={{
                    height:1,
                    "@media screen and (max-width: 47.9375em)" : {
                        paddingTop:0,
                        paddingX:0,
                    }
                }}
            >
                

                    <ShDataGrid2 key={theme.palette.mode}
                        itemId={customer_id}
                        content={useGetUsersQuery}
                        gridActions={<GridActions handleOpen={() => setAddModalOpen(true)}></GridActions>} 
                        title={t("overview")}
                        gridOptions={gridOptions} 
                        columns={columns} 
                        isSidepanelGrid
                        onRowClick={getUserDetails}
                        rows={data}>
                    </ShDataGrid2>
                        


                    <MpAddUser onOpen={() => addModal.setOpen(true)} onClose={() => addModal.setOpen(false)} open={addModal.open} handleClose={handleClose}/>
                    <SpUserPermissionDetails isExtended={isPermissionExtended} setIsExtended={setIsPermissionExtended}/>
                    <SpUserDetails isExtended={isExtended} setIsExtended={setIsExtended} userId={userId}/>
                    {/* { <SpShipperDetails
                        isExtended={isExtended}
                        setIsExtended={setIsExtended}
                        data={shipperData}                
                    ></SpShipperDetails> } */}
                </Box>
        : null
    )
});



export default All;