import { Box } from "@mui/material";
import { DataGrid, GridToolbar, useGridApiRef } from "@mui/x-data-grid";
import { useTheme } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import ShDataGrid from "../../../../theme/datagrid/ShDataGrid";
import Inventory2Icon from '@mui/icons-material/Inventory2';
import { tokens } from "../../../../../theme";
import Shbutton from "../../../../theme/buttons/Shbutton";
import AddIcon from '@mui/icons-material/Add';
import WarehouseIcon from '@mui/icons-material/Warehouse';
import Icon from "../../../../theme/icons/Icon";
import Text from "../../../../theme/text/Text";

import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import ShDateRangePicker from "../../../../theme/ShDateRangePicker";
import { useTranslation } from "react-i18next";
import SPLocationDetails from "../../SpLocationDetails/SpLocationDetails";
import { useGetStockForProductQuery, useGetStockQuery } from "../../../../../newapi/warehouse/stockSlice";
import ShTextSkeleton from "../../../../theme/skeleton/ShTextSkeleton";
import { useGetWarehouseLocationQuery, useGetWarehouseLocationSpQuery } from "../../../../../newapi/warehouse/warehouselocationSlice";
import ShDataGrid2 from "../../../../theme/datagrid2/ShDataGrid2";
import ShLabel from "../../../../theme/label/ShLabel";

const GridActions = (props) => {
    return (<></>)
}

let gridOptions = {
    export:true,
    disableCustomActionsOnPhone: true,
    isSidepanel: true,
    filter : {
        name: "stockFilter",
        selectedFilter : null,
        groups : [
            {
                conditionExtensionType : 0,
                rules : [
                ]
            }
        ]
    }
};

const Warehouse = (props) => {
    const  { t } = useTranslation();
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const [locationIsExtended, setLocationIsExtended] = useState(false);
    // const [data, setData] = useState([]);
    // const [dataHasBeenSet, setDataHasBeenSet] = useState();
    const [config,setConfig] = useState({productId: props.productId})

    

    useEffect(() => {
        props.replaceView({
            ...props.config,
            gridOptions: gridOptions,
            mobileActions: <GridActions />,
        });

        return () => {
            // console.log("unmounting warehouse");
        }
    },[]);
    
    
    // const [locationId, setLocationId] = useState(null);
    // const { data: locationData, isLoading: isLoadingLocation } = useGetWarehouseLocationSpQuery(locationId,
    //     {
    //         skip: !locationId
    //     });
    // const getLocationDetails = (location) => {
    //     console.log(location);
    //     setLocationId(location.id);
    //     setLocationIsExtended(true);
    //     // getWarehouseLocation((result, data) => {
    //     //     if (result) {
    //     //         setLocationData(data);
    //     //         setLocationIsExtended(true);
    //     //     }
    //     // }, params.id);
    // }
    // useEffect(() => {
    //     if(!isLoadingLocation) {
    //         console.log("locationData",locationData);

    //     }
    // }, [isLoadingLocation])


    const [locationId, setLocationId] = useState();
    const locationIdRef = useRef(locationId);
    
    const getLocationDetails = (params) => {
        if (!params) return;
    
        const newProductId = parseInt(params.row.warehouseLocation.id);
        // Only update state if the productId has actually changed
        if (locationIdRef.current !== newProductId) {
            setLocationId(newProductId);
            locationIdRef.current = newProductId; // Update the ref to the new value
        }
        setLocationIsExtended(true);
    };
    

    const columns = [
        // {
        //     field: "manual",
        //     headerName: t('manual'),
        //     flex:1,
        //     renderCell : (params) => {
        //         if(params.row.skeleton) {
        //             return <ShTextSkeleton/>
        //         }
        //         return params.row.warehouseLocation.carrier
        //     },
        //     hidden: true,
        //     shFilter : {
        //         type: "boolean",
        //     }
        // },
        {
            field: "location", 
            headerName: t('location'), 
            width:216,
            shFilter : {
                type: "text",
            },
            renderCell: (params) => {
                if(params.row.skeleton) {
                    return <ShTextSkeleton/>
                }
                const { warehouseLocation } = params.row;
                const iconMap = {
                  bulk: "box",
                  transition: "truck",
                  general: "pick",
                  link: "link",
                };
              
                return (
                    <Box>
                        <Text bold>
                            {warehouseLocation.location}
                        </Text>
                        <br/>
                        <Text light>
                            {warehouseLocation.warehouse?.name}
                        </Text>
                    </Box>
                //   <Box 
                //     sx={{
                //         background: theme.palette.mode === "dark" ? colors.blue[100] : colors.primary[100],
                //         pb:1,
                //         borderRadius:3
                //     }}
                //   >
                //     <Box
                //         sx={{
                //             pl:1.5,
                //             display:"flex",
                //             alignItems:"flex-start",
                //             justifyContent:"space-between",
                //         }}
                //     >
                //         <Box pt={1}>
                //             <Text clickable bold variant={theme.palette.mode === "dark" ? "blue" : "primary"}>
                //                 {warehouseLocation.location}
                //             </Text>
                //         </Box>
                //         <Box
                //             sx={{
                //                 background: theme.palette.mode === "dark" ? colors.blue[400] : colors.primary[400],
                //                 height:24,
                //                 px:1,
                //                 color: theme.palette.mode === "dark" ? colors.txt['darkened'] : colors.txt["light"],
                //                 lineHeight:"24px",
                //                 borderTopRightRadius:"12px",
                //                 borderBottomRightRadius:"6px",
                //                 borderTopLeftRadius:"6px",
                //                 borderBottomLeftRadius:"12px",
                //             }}
                //         >

                //             <Text semibold color={theme.palette.mode === "dark" ? colors.txt['darkened'] : colors.txt["light"]}>
                //                 {iconMap[warehouseLocation.warehouseLocationType.type] || ""}
                //             </Text>
                //         </Box>
                //     </Box>
                //     <Box px={1.5}>
                //         <Text >
                //         {warehouseLocation.warehouse?.name}
                //         </Text>
                //     </Box>
                //   </Box>
                );
              }
            },
            {
                field: "location_type",
                headerName: t('location_type'),
                flex:1,
                renderCell : (params) => {
                    if(params.row.skeleton) {
                        return <ShTextSkeleton/>
                    }

                    const { warehouseLocation } = params.row;

                    const iconMap = {
                        bulk: "box",
                        transition: "truck",
                        general: "pick",
                        link: "link",
                    };

                    const type = iconMap[warehouseLocation.warehouseLocationType.type] || warehouseLocation.warehouseLocationType.type;

                    const getColor = (icon) => {
                        switch(icon) {
                            case iconMap.bulk :
                                return "pink";
                            case iconMap.transition :
                                return "cyan";
                            case iconMap.general :
                                return theme.palette.mode === "dark" ? "blue" : "primary";

                            case iconMap.link :
                                return "purple";
                            default :
                                return "grey"
                        }
                    }

                    return (
                        <ShLabel size={32} palette={theme.palette.mode === "dark" ? "pastel" : "normal"} variant={getColor(type)}>
                            {type}
                        </ShLabel>
                    )
                },
            },
        {
            field: "administrative",
            headerName: t('administrative'),
            flex:1,
            renderCell : (params) => {
                if(params.row.skeleton) {
                    return <ShTextSkeleton/>
                }
                return params.row.available ?? 0
            },
            shFilter : {
                type: "number",
            }
        },
        {
            field: "physical",
            headerName: t('physical'),
            flex:1,
            renderCell : (params) => {
                if(params.row.skeleton) {
                    return <ShTextSkeleton/>
                }
                return params.row.quantity ?? 0
            },
            shFilter : {
                type: "number",
            }
        },
        {
            field: "reserved",
            headerName: t('reserved'),
            flex:1,
            renderCell : (params) => {
                if(params.row.skeleton) {
                    return <ShTextSkeleton/>
                }
                return params.row.reserved ?? 0
            },
            shFilter : {
                type: "number",
            }
        },
        {
            field: "blocked", 
            headerName: t('blocked'),
            flex:1,
            renderCell : (params) => {
                if(params.row.skeleton) {
                    return <ShTextSkeleton/>
                }
                return params.row.blocked ?? 0
            },
            shFilter : {
                type: "number",
            }
        },
        {
            field: "pickingprocess", 
            headerName: t('in_pick_process'),
            flex:1,
            renderCell : (params) => {
                if(params.row.skeleton) {
                    return <ShTextSkeleton/>
                }
                return params.row.pickingprocess ?? 0
            },
            shFilter : {
                type: "number",
            }
        },
        // {
        //     field: "pickedUp", 
        //     headerName: t('picked_up'),
        //     flex:1,
        //     renderCell : (params) => {
        //         if(params.row.skeleton) {
        //             return <ShTextSkeleton/>
        //         }
        //         return params.row.available -5
        //     },
        //     shFilter : {
        //         type: "number",
        //     }
        // },
        {
            field: "phoneView", 
            headerName: "PhoneView",
            cellClassName: "phone-column--cell",
            renderCell: (params) => {
                if(params.row.skeleton) {
                    return <ShTextSkeleton/>
                }
                return (
                    <Box
                        sx={{
                            background:`${theme.palette.mode === "dark" ? colors.grey[300] : colors.grey[100]}`,
                            paddingX:2,
                            paddingY:1.5,
                            borderRadius:2
                        }}
                    >
                        {params.row.state + " " + params.row.available}
                        <br/>
                        {params.row.backorder}
                        <br/>
                        {params.row.backorder}
                    </Box>
                )
            }
        }
    ];

    return (
        <Box height={1} display={"flex"} flexDirection={"column"}>
            {/* <GridActions></GridActions> */}
            <ShDataGrid2 key={theme.palette.mode}
                content={useGetStockForProductQuery}
                onRowClick={getLocationDetails}
                config={config}
                gridActions={<GridActions></GridActions>} 
                title={t("overview")}
                gridOptions={gridOptions} 
                isSidepanelGrid 
                columns={columns}>
            </ShDataGrid2>
            <SPLocationDetails
                isExtended={locationIsExtended}
                setIsExtended={setLocationIsExtended}
                locationId={locationId}
                // data={locationData} // Pass the locationData as a prop
            ></SPLocationDetails>
        </Box>
    );
}
export default Warehouse;