import { Box, useTheme } from "@mui/material";
import { forwardRef, useEffect, useRef, useState } from "react";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { tokens } from "../../../../../../theme"; 
import Inventory2OutlinedIcon from '@mui/icons-material/Inventory2Outlined';
import ShDataGrid from "../../../../../../components/theme/datagrid/ShDataGrid";
import Text from "../../../../../../components/theme/text/Text";
import AddIcon from '@mui/icons-material/Add';
import ButtonGroup from "../../../../../../components/theme/buttons/ButtonGroup";
import Shbutton from "../../../../../../components/theme/buttons/Shbutton";
import { useTranslation } from "react-i18next";

import { useFindAllWorkspacesQuery, useFindWorkspaceByIdQuery } from "../../../../../../newapi/warehouse/workspaceSlice";
import MAddWorkspace from "../../../../../../components/global/Modals/MAddWorkspace";
import SpWorkspaceDetails from "../../../../../../components/global/Sidepanels/SpWorkspaceDetails/SpWorkspaceDetails";
import ShDataGrid2 from "../../../../../../components/theme/datagrid2/ShDataGrid2";

const GridActions = (props) => {
    const theme = useTheme();
    const { t } = useTranslation();
    return (      
        <ButtonGroup version={2}>
            <Shbutton 
                onClick={() => props.handleOpen(true)}
                className={"group-btn"} 
                color={theme.palette.mode === "dark" ? "blue" : "primary"} 
                variant="contained" 
                endIcon={<AddIcon/>}>{t("add_workspace")}</Shbutton>
        </ButtonGroup>
    )
}

const WareHouseDropdown = (props) => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    return(
        <Box
            sx={{
                display:"flex",
                alignItems:"center",
                justifyContent:"center",
                gap:2,
                height:40,
                background:theme.palette.mode === "dark" ? colors.grey["400"] : colors.txt["primary"],
                borderRadius:5,
                paddingX:2,
                paddingRight:1,
                borderTopRightRadius:0,
                borderBottomRightRadius:0,
                color:"#fff"
            }}
            onClick={() => props.setOpen(true)}
        >
            <Box>
                <Text bold variant="blue">t("all")</Text>&nbsp;<Text variant="white">t("warehouses")</Text>
            </Box>
            <KeyboardArrowDownIcon/>
        </Box>
    )
}
const gridOptions = {
    export: true,
    disableCustomActionsOnPhone: true,
    isSidepanel: true,
    filter: {
        name: "workspaceFilter",
        selectedFilter: null,
        groups: [{ conditionExtensionType: 0, rules: [] }]
    }
};

const All = forwardRef((props, ref) => {
    const { t } = useTranslation();
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const [data, setData] = useState([]);
    const [isExtended, setIsExtended] = useState(false);
    // const [selectedWorkspaceId, setSelectedWorkspaceId] = useState(null);
    // const { data: workspaceData, error, isLoading } = useFindWorkspaceByIdQuery(selectedWorkspaceId, { skip: !selectedWorkspaceId });
    const [addModalOpen, setAddModalOpen] = useState(false);

    // useEffect(() => {
    //     if (selectedWorkspaceId) {
    //         setIsExtended(true);
    //     }
    // }, [selectedWorkspaceId]);

    // useEffect(() => {
    //     if (!isExtended) {
    //         setSelectedWorkspaceId(null);
    //     }
    // }, [isExtended]);
    

    const [workspaceId, setWorkspaceId] = useState();
    const workspaceIdRef = useRef(workspaceId);
    
    const getWorkspaceDetails = (params) => {
        if (!params) return;
    
        const newworkspaceId = parseInt(params.row.id);
        // Only update state if the workspaceId has actually changed
        if (workspaceIdRef.current !== newworkspaceId) {
            setWorkspaceId(newworkspaceId);
            workspaceIdRef.current = newworkspaceId; // Update the ref to the new value
        }
        setIsExtended(true);
    };

    useEffect(() => {
        props.replaceView({
            ...props.config,
            gridOptions:gridOptions,
            topRight: <WareHouseDropdown {...props.warehouseModal}/>,
            mobileActions: <GridActions handleOpen={() => setAddModalOpen(true)} />
        });

        return () => {
            // console.log("unmounting warehouse");
        }
    },[props]);

    

    // const getWorkspaceDetails = (row) => {
    //     if (row) {
    //         setSelectedWorkspaceId(row.id);
    //     }
    // };

    const handleClose = () => {
        setAddModalOpen(false);
    };

    const columns = [
        {
            field: "name",
            headerName: t("workspace") ,
            flex: 2,
            cellClassName: "workspace",
            renderCell: (cellValues) => (
                <Text semibold variant={theme.palette.mode === "dark" ? "blue" : "primary"}>{cellValues.value}</Text>
            )
        },
        {
            field: "packingListPrinter",
            headerName: t("packinglist_printer") ,
            flex: 1,
            cellClassName: "printer_packinglist",
            renderCell: (cellValues) => (
                <Text>{cellValues.row.packingListPrinter?.description}</Text>
            )
        },
        {
            field: "labelPrinter",
            headerName: t("label_printer") ,
            flex: 1,
            cellClassName: "printer_label",
            renderCell: (cellValues) => (
                <Text>{cellValues.row.labelPrinter?.description}</Text>
            )
        },
        {
            field: "documentsPrinter",
            headerName: t("documents_printer") ,
            flex: 1,
            cellClassName: "printer_documents",
            renderCell: (cellValues) => (
                <Text>{cellValues.row.documentsPrinter?.description}</Text>
            )
        },
        {
            field: "autoPrint",
            headerName: t("auto_print") ,
            flex: 1,
            cellClassName: "autoPrint",
            renderCell: (cellValues) => (
                <Text>{cellValues.row.autoPrint ? t("yes") : t("no")}</Text>
            )
        },
        { field: "warehouseId", headerName: t("warehouse"), flex: 1, cellClassName: "printer-warehouse-id",
        renderCell: (cellValues) => {
            return(<Text>{cellValues.row.warehouse?.name}</Text>)
        }
    },
        {
            field: "phoneView",
            headerName: "PhoneView",
            cellClassName: "phone-column--cell",
            renderCell: (cellValues) => {
                // Custom cell rendering
            }
        }
    ];

    return (
        <Box
            sx={{
                height: 1,
                "@media screen and (max-width: 47.9375em)": {
                    paddingTop: 0,
                    paddingX: 0,
                }
            }}
        >
            <Box
                sx={{
                    background: theme.palette.mode === "dark" ? colors.grey[200] : colors.grey[0],
                    flex: 1,
                    borderTopLeftRadius: 16,
                    borderTopRightRadius: 16,
                    "@media screen and (max-width: 48em)": {
                        background: theme.palette.mode === "dark" ? colors.grey[200] : colors.grey[0],
                    }
                }}
            >
                <ShDataGrid2 key={theme.palette.mode}
                    content={useFindAllWorkspacesQuery}
                    gridActions={<GridActions handleOpen={() => setAddModalOpen(true)} />}
                    title={t("overview")}
                    gridOptions={gridOptions}
                    columns={columns}
                    isSidepanelGrid
                    onRowClick={getWorkspaceDetails}
                    rows={data}
                />
            </Box>

            <MAddWorkspace onOpen={() => setAddModalOpen(true)} onClose={handleClose} open={addModalOpen} handleClose={handleClose} />
            <SpWorkspaceDetails
                isExtended={isExtended}
                setIsExtended={setIsExtended}
                workspaceId={workspaceId}
                // data={workspaceData}
            />
        </Box>
    )
});

export default All;
