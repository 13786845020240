import { Box, InputBase } from "@mui/material";
import { useTheme } from "@mui/material";
import { tokens } from "../../../../../theme";
import Shbutton from "../../../../theme/buttons/Shbutton";
import SaveIcon from '@mui/icons-material/Save';
import Text from "../../../../theme/text/Text";
import Dropdown from "../../../../theme/dropout/DropOut";
import { useEffect, useState } from "react";
import CropFreeIcon from '@mui/icons-material/CropFree';
import ButtonGroup from "../../../../theme/buttons/ButtonGroup";
import { useDeleteWebshopCredentialsMutation, useEditWebshopCredentialsMutation, useSyncWebshopProductsMutation } from "../../../../../newapi/webshop/webshopSlice";

import { useTranslation } from "react-i18next";
import { InputDropdown } from "../../../../theme/dropdowns/InputDropdown";
import { showToastMessageDeleteRequest, showToastMessageUpdateRequest} from "../../../../../utils/toasts";
import DeleteIcon from '@mui/icons-material/Delete';
import MConfirmDelete from "../../../Modals/MConfirmDelete";

const GridActions = (props) => {
    const theme = useTheme();
    const { t } = useTranslation();

    return (
        <ButtonGroup version={2}>
            <Shbutton
                className={"group-btn"}
                onClick={props.deleteAction}
                color="red"
                variant="contained"
                endIcon={<DeleteIcon />}
            >
                {t('delete')}
            </Shbutton>
            <Shbutton
                className={"group-btn"}
                onClick={props.action}
                color={theme.palette.mode === "dark" ? "blue" : "primary"}
                variant="contained"
                endIcon={<SaveIcon />}
            >
                {t('save')}
            </Shbutton>
        </ButtonGroup>
    );
};

const getWebshopElements = (t) => ({
    woocommerce: (
        <>
            <Box lineHeight={"40px"} sx={{paddingTop: 2}}>
                <Text>{t("webshop_url")}</Text>
            </Box>
            <Box lineHeight={"40px"} sx={{paddingTop: 2}}>
                <Text>{t("webshop_consumer_key")}</Text>
            </Box>
            <Box lineHeight={"40px"} sx={{paddingTop: 2}}>
                <Text>{t("webshop_consumer_secret")}</Text>
            </Box>
        </>
    ),
    shopify: (
        <>
            <Box lineHeight={"40px"} sx={{paddingTop: 2}}>
                <Text>{t("shop")}</Text>
            </Box>
            <Box lineHeight={"40px"} sx={{paddingTop: 2}}>
                <Text>{t("client_key")}</Text>
            </Box>
            <Box lineHeight={"40px"} sx={{paddingTop: 2}}>
                <Text>{t("access_token")}</Text>
            </Box>
        </>
    ),
    bol: (
        <>
            <Box lineHeight={"40px"} sx={{paddingTop: 2}}>
                <Text>{t("api_key")}</Text>
            </Box>
        </>
    ),
});

const getWebshopInputFields = (colors, credentialsInfo, handleChange) => {
    const commonStyles = {
        display: "flex",
        height: "40px",
        borderRadius: "20px",
        backgroundColor: colors.grey[100],
        sx: {
            marginTop: 2,
            justifyContent: "space-between",
            "@media screen and (max-width: 47.9375em)": {
                flexDirection: "column"
            }
        }
    };

    const webshopConfigs = {
        woocommerce: [
            {
                value: credentialsInfo?.credentials?.url || "",
                onChange: (e) => handleChange('credentials.url', e.target.value),
                styles: commonStyles
            },
            {
                value: credentialsInfo?.credentials?.consumer_key || "",
                onChange: (e) => handleChange('credentials.consumer_key', e.target.value),
                styles: commonStyles
            },
            {
                value: credentialsInfo?.credentials?.consumer_secret || "",
                onChange: (e) => handleChange('credentials.consumer_secret', e.target.value),
                styles: commonStyles
            }
        ],
        shopify: [
            {
                value: credentialsInfo?.credentials?.shop || "",
                onChange: (e) => handleChange('credentials.shop', e.target.value),
                styles: commonStyles
            },
            {
                value: credentialsInfo?.credentials?.client_key || "",
                onChange: (e) => handleChange('credentials.client_key', e.target.value),
                styles: commonStyles
            },
            {
                value: credentialsInfo?.credentials?.access_token || "",
                onChange: (e) => handleChange('credentials.access_token', e.target.value),
                styles: commonStyles
            }
        ],
        bol: [
            {
                value: credentialsInfo?.credentials?.api_key || "",
                onChange: (e) => handleChange('credentials.api_key', e.target.value),
                styles: commonStyles
            }
        ]
    };

    return webshopConfigs[credentialsInfo?.webshop_type] || [];
};

const DetailsPanel = (props) => {
    const  { t } = useTranslation();
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const [open, setOpen] = useState(false);

    const [credentialsInfo, setCredentialsInfo] = useState({
      id: 0,
      dimensions: null,
      webshopcredentials: null,
      webshop_type: "",
      description: "",
      token: ""
    });

    const [credentialsArray, setCredentialsArray] = useState([{key: "", credentials: {}}]);

   // const { data: shippers } = useGetShippersSelectQuery();

    useEffect(() => {
        setCredentialsInfo(props.data);

        if (props.data?.credentials) {
            setCredentialsArray([{key: props.data.webshop_type, credentials: props.data.credentials}]);
        }
    },[props.data]);

    const handleChange = (key, value) => {
        setCredentialsInfo(credentialsInfo => {
            // Update credentialsInfo as before
            const updatedCredentialsInfo = { ...credentialsInfo };
            const keys = key.split('.');
    
            if (keys.length === 2) {
                // Assuming keys[0] is 'credentials', update nested property
                updatedCredentialsInfo[keys[0]] = {
                    ...updatedCredentialsInfo[keys[0]],
                    [keys[1]]: value
                };
            } else if (keys.length === 3) {
                updatedCredentialsInfo[keys[0]] = {
                    ...updatedCredentialsInfo[keys[0]],
                    [keys[1]]: {
                        ...updatedCredentialsInfo[keys[0]][keys[1]],
                        [keys[2]]: value
                    }
                };
            } else {
                let credentials = updatedCredentialsInfo.credentials;
                if (key === "webshop_type") {
                    credentials = getCredentialsFromWebshopType(value);
                }
                updatedCredentialsInfo[key] = value;
                updatedCredentialsInfo["credentials"] = credentials;
            }
    
            if (keys.length > 1 && keys[0] === "credentials") {
                // Update credentialsArray
                setCredentialsArray(credentialsArray => {
                    const index = credentialsArray.findIndex(item => item.key === updatedCredentialsInfo.webshop_type);
                    let updatedCredentialsArray = [...credentialsArray];
        
                    if (index !== -1) {
                        // Update existing item
                        updatedCredentialsArray[index] = {
                            ...updatedCredentialsArray[index],
                            credentials: updatedCredentialsInfo.credentials
                        };
                    } else {
                        // Add new item if not found
                        updatedCredentialsArray.push({
                            key: updatedCredentialsInfo.webshop_type,
                            credentials: updatedCredentialsInfo.credentials
                        });
                    }
        
                    return updatedCredentialsArray;
                });
            }
    
            return updatedCredentialsInfo;
        });
    };

    const webshopTypes = [
        {value: "woocommerce", name: "Woocommerce"},
        {value: "shopify", name: "Shopify"},
        {value: "bol", name: "Bol."},
    ];

    const dimensionUnits = [
        {value: "cm", name: "cm"},
        {value: "mm", name: "mm"},
    ];

    const weightUnits = [
        {value: "kg", name: "kg"},
        {value: "gram", name: "gram"},
    ];

    const inputFields = getWebshopInputFields(colors, credentialsInfo, handleChange);
    const webshopElements = getWebshopElements(t);

    const renderWebshopSpecificElements = (webshopType) => {
        return webshopElements[webshopType] || null; // Return null if the webshop type is not found
    };

    const getCredentialsFromWebshopType = (webshopType) => {
        let webshopCredentialsObject = credentialsArray.find(c => c.key === webshopType);
        if (webshopCredentialsObject) { 
            return webshopCredentialsObject.credentials;
        } else if (webshopType === "woocommerce") {
            webshopCredentialsObject = {key: "woocommerce", credentials: {
                url: "",
                consumer_key: "",
                consumer_secret: "",
                measuring_units: { weight: "gram", dimensions: "cm"}
            }};

            credentialsArray.push(webshopCredentialsObject);
            return webshopCredentialsObject.credentials;
        } else if (webshopType === "shopify") {
            webshopCredentialsObject = {key: "shopify", credentials: {
                shop: "",
                client_key: "",
                access_token: "",
                measuring_units: { weight: "gram", dimensions: "cm"}
            }};

            credentialsArray.push(webshopCredentialsObject);
            return webshopCredentialsObject.credentials;
        } else if (webshopType === "bol") {
            webshopCredentialsObject = {key: "bol", credentials: {
                api_key: "",
                measuring_units: { weight: "gram", dimensions: "cm"}
            }};

            credentialsArray.push(webshopCredentialsObject);
            return webshopCredentialsObject.credentials;
        }
    }

    
    
    const [EditWebshopCredentials,{isLoading}] = useEditWebshopCredentialsMutation();
    const handleSaveClick = async () => {
        try {
            const response = await EditWebshopCredentials(credentialsInfo).unwrap();
            showToastMessageUpdateRequest(t, response);
        } catch (err) {
            showToastMessageUpdateRequest(t, {success: false, error: err.error});
        }
    };

    const [SyncWebshopProducts,{syncIsLoading}] = useSyncWebshopProductsMutation();
    const handleSyncClick = async () => {
        try {
            const response = await SyncWebshopProducts({id: credentialsInfo?.id}).unwrap();
            showToastMessageUpdateRequest(t, response);
        } catch (err) {
            showToastMessageUpdateRequest(t, {success: false, error: err.error});
        }
    };
    const [DeleteWebshopCredentialsMutation] = useDeleteWebshopCredentialsMutation();
    const handleDeleteClick = async () => {
      setOpen(true);
    };
    const confirmDelete = async () => {
        try {
            const response = await DeleteWebshopCredentialsMutation(props.data.id).unwrap();
            showToastMessageDeleteRequest(t, response);
            props.setIsExtended(false);
        } catch (err) {
            showToastMessageDeleteRequest(t, {success: false, error: err.error});
        }
    };

    const confirmDeleteModal = {
        data: credentialsInfo?.description,
        open: open,
        setOpen: setOpen,
        confirm: confirmDelete
    }

    useEffect(() => {
        props.replaceView({
            ...props.config,
            mobileActions:<GridActions 
                id={credentialsInfo?.id}
                action={handleSaveClick}
                syncAction={handleSyncClick}></GridActions>,
        });

        return () => {
            // console.log("unmounting warehouse");
        }
    },[credentialsInfo,handleSaveClick,handleSyncClick]);

    return (
            <Box height={1} display={"flex"} flexDirection={"column"} paddingX={3}
                sx={{
                    "@media screen and (min-width: 48.1em)"  :{
                        marginBottom:11
                    }
                }}
            >
                <Dropdown title={t("webshop")} open>
                    <Box sx={{
                        display:"flex",
                        justifyContent:"space-between",
                        "@media screen and (max-width: 47.9375em)" : {
                            flexDirection:"column"
                        }
                    }}>
                        <InputDropdown
                                variant="darker"
                                displayName={"name"} 
                                onChange={(selected) => handleChange('webshop_type', webshopTypes[selected].value)}
                                noSelection={t("choose_webshop")} //Custom text when nothing is selected
                                options={webshopTypes ?? null}
                                selected={webshopTypes?.findIndex(type => type.value === credentialsInfo?.webshop_type) ?? -1}
                                closeOnSelection
                            />
                    </Box>
                    <Box sx={{
                        display:"flex",
                        justifyContent:"space-between",
                        "@media screen and (max-width: 47.9375em)" : {
                            flexDirection:"column"
                        }
                    }}>
                        
                        {/* Two columns */}
                        
                        <Box flex={1}
                            sx={{
                                display:"flex",
                                gap: 4,
                                justifyContent:"flex-start"
                            }}
                        >
                            <Box
                                sx={{
                                    "@media screen and (max-width: 47.9375em)" : {
                                        flex:1
                                    }
                                }}
                            >
                                {renderWebshopSpecificElements(credentialsInfo?.webshop_type)}
                            </Box>
                            <Box flex={6}>
                            {inputFields.map((field, index) => (
                                <Box key={index} {...field.styles}>
                                    <InputBase
                                        sx={{ ml: 2, flex: 1 }}
                                        value={field.value}
                                        onChange={field.onChange}
                                    />
                                </Box>
                            ))}
                            </Box>
                        </Box>
                    </Box>
                    
                </Dropdown>
                
                
                <Dropdown title={t("dimensions_and_weight")} isLast open>
                    <Box sx={{
                        display:"flex",
                        justifyContent:"space-between",
                        "@media screen and (max-width: 47.9375em)" : {
                            flexDirection:"column"
                        }
                    }}>
                        
                        {/* Two columns */}
                        
                        <Box flex={1}
                            sx={{
                                display:"flex",
                                gap: 4,
                                justifyContent:"flex-start"
                            }}
                        >
                            <Box
                                sx={{
                                    "@media screen and (max-width: 47.9375em)" : {
                                        flex:1
                                    }
                                }}
                            >
                                <Box lineHeight={"40px"}>
                                    <Text>{t("weight")}</Text>
                                </Box>
                                <Box lineHeight={"40px"} sx={{paddingTop: 2}}>
                                    <Text>{t("dimensions")}</Text>
                                </Box>
                            </Box>
                            <Box flex={1}>
                                <Box display="flex"
                                    height="40px"
                                    // maxWidth="400px"
                                    borderRadius="20px"
                                    backgroundColor={colors.grey[100]}
                                    sx={{
                                        justifyContent:"space-between",
                                        "@media screen and (max-width: 47.9375em)" : {
                                            flexDirection:"column"
                                        }
                                    }}
                                >
                                    <InputDropdown
                                        displayName={"name"} 
                                        onChange={(selected) => handleChange('credentials.measuring_units.weight', weightUnits[selected].value)}
                                        noSelection={t("weight")} //Custom text when nothing is selected
                                        options={weightUnits ?? null}
                                        selected={weightUnits?.findIndex(type => credentialsInfo?.credentials?.measuring_units && type.value === credentialsInfo?.credentials.measuring_units.weight) ?? -1}
                                        closeOnSelection
                                    />
                                </Box>
                                <Box display="flex"
                                    height="40px"
                                    // maxWidth="400px"
                                    borderRadius="20px"
                                    backgroundColor={colors.grey[100]}
                                    sx={{
                                        marginTop:2,
                                        justifyContent:"space-between",
                                        "@media screen and (max-width: 47.9375em)" : {
                                            flexDirection:"column"
                                        }
                                    }}
                                >
                                    <InputDropdown
                                        displayName={"name"} 
                                        onChange={(selected) => handleChange('credentials.measuring_units.dimensions', dimensionUnits[selected].value)}
                                        noSelection={t("dimensions")} //Custom text when nothing is selected
                                        options={dimensionUnits ?? null}
                                        selected={dimensionUnits?.findIndex(type => credentialsInfo?.credentials?.measuring_units && type.value === credentialsInfo?.credentials.measuring_units.dimensions) ?? -1}
                                        closeOnSelection
                                    />
                                </Box>
                            </Box>
                        </Box>
                    </Box>
                    
                </Dropdown>
                    
                <Box
                    sx={{
                        position:"absolute",
                        bottom:0,
                        right:0,
                        padding:3,
                        width:1,
                        background:theme.palette.mode === "dark" ? colors.grey[200] : colors.grey[0],
                        "@media screen and (min-width: 47.9376em) and (max-width: 63.9375em)" : {
                            padding:2,
                            paddingY:2.5,
                        }
                    }}
                >
                <GridActions
                    id={credentialsInfo?.id}
                    action={handleSaveClick}
                    syncAction={handleSyncClick}
                    deleteAction={handleDeleteClick}
                />
                <MConfirmDelete
                open={confirmDeleteModal.open}
                handleClose={() => confirmDeleteModal.setOpen(false)}
                data={confirmDeleteModal.data}
                confirm={confirmDeleteModal.confirm}
            />
                </Box>
                
            </Box>
    );
}

export default DetailsPanel;