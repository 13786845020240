import { Box, IconButton, useTheme } from "@mui/material";
import { forwardRef, useEffect, useImperativeHandle, useRef, useState } from "react";
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
// import { tokens } from "../../../../../theme";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { tokens } from "../../../../../theme"; 
import Inventory2OutlinedIcon from '@mui/icons-material/Inventory2Outlined';
import ShDataGrid from "../../../../../components/theme/datagrid/ShDataGrid";
import Subheading from "../../../../../components/theme/text/Subheading";
import Text from "../../../../../components/theme/text/Text";

import AddIcon from '@mui/icons-material/Add';
import ButtonGroup from "../../../../../components/theme/buttons/ButtonGroup";
import Shbutton from "../../../../../components/theme/buttons/Shbutton";

import CheckOutlinedIcon from '@mui/icons-material/CheckOutlined';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import HorizontalRuleOutlinedIcon from '@mui/icons-material/HorizontalRuleOutlined';

import AllCarriersCard from "../../../../../components/theme/cards/carriers/AllCarriersCard";
import SpCarrierDetails from "../../../../../components/global/Sidepanels/SpCarrierDetails/SpCarrierDetails";
import { useTranslation } from "react-i18next";
import MAddCarrier from "../../../../../components/global/Modals/carriers/AddCarrier";
import { useGetShippersQuery, useGetShipperQuery } from "../../../../../newapi/shipper/shipperSlice";
import MpAddShipper from "../../../../../components/global/ModalsProgression/MpAddShipper/MpAddShipper";
import SpShipperDetails from "../../../../../components/global/Sidepanels/SpShipperDetails/SpShipperDetails";
import { ReactComponent as DhlLogo } from '../../../../../styles/svg/dhl.svg';
import { ReactComponent as DpdLogo } from '../../../../../styles/svg/dpd.svg';
import { ReactComponent as PostNLLogo } from '../../../../../styles/svg/postnl.svg';
import { ReactComponent as BolLogo } from '../../../../../styles/svg/bol.svg';
import { GridCheckCircleIcon, GridCheckIcon } from "@mui/x-data-grid";
import { ClearIcon } from "@mui/x-date-pickers";
import {QuestionMarkOutlined} from "@mui/icons-material";
import ShDataGrid2 from "../../../../../components/theme/datagrid2/ShDataGrid2";


const GridActions = (props, i18n) => {
    const theme = useTheme();
    useEffect(() => {

    },[props.open]);
    const {t} = useTranslation();
    return (      
        <ButtonGroup version={2}>
            <Shbutton 
                onClick={() => props.handleOpen(true)}
                className={"group-btn"} 
                color={theme.palette.mode === "dark" ? "blue" : "primary"} 
                variant="contained" 
                endIcon={<AddIcon/>}>{t("add_shipper")}</Shbutton>
        </ButtonGroup>
    )
}

const WareHouseDropdown = (props) => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    return(
        <Box
            sx={{
                display:"flex",
                alignItems:"center",
                justifyContent:"center",
                gap:2,
                height:40,
                background:theme.palette.mode === "dark" ? colors.grey["400"] : colors.txt["primary"],
                borderRadius:5,
                paddingX:2,
                paddingRight:1,
                borderTopRightRadius:0,
                borderBottomRightRadius:0,
                color:"#fff"
            }}
            onClick={() => props.setOpen(true)}
        >
            <Box>
                <Text bold variant="blue">t("all")</Text>&nbsp;<Text variant="white">t("warehouses")</Text>
            </Box>
            <KeyboardArrowDownIcon/>
        </Box>
    )
}


const gridOptions = {
    export:true,
    disableCustomActionsOnPhone: true,
    isSidepanel: true,
    
    filter : {
        name: "stockFilter",
        selectedFilter : null,
        groups : [
            {
                conditionExtensionType : 0,
                rules : [
                ]
            }
        ]
    }
};


const All = forwardRef((props, ref) => {
    const { t } = useTranslation();
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const [data, setData] = useState([]);
    const [isExtended,setIsExtended] = useState(false);
    // const { getCarrier} = useGetCarrierQuery();
    const [selectedRowId, setSelectedRowId] = useState(1);
    const [rowSelected, setRowSelected] = useState(false);
    // const { data: shipperData, error, isLoading } = useGetShipperQuery(
    //    selectedRowId,
    //     {
    //         skip: !rowSelected
    //     }
    // );
    


    const [addModalOpen, setAddModalOpen] = useState(false);
    const addModal = {
        open: addModalOpen,
        setOpen: setAddModalOpen
    }

    const handleClose = () => {
        setAddModalOpen(false);
      };

      

    // const getShipperDetails = (row) => {  
    //     if (!row) return;
    //     setSelectedRowId(row.id);
    //     setRowSelected(true);
    // };

    // useEffect(() => {
    //     if (!isLoading && shipperData && rowSelected) {
    //         setIsExtended(true);
    //     }
    // }, [shipperData, isLoading, rowSelected]);

    // useEffect(() => {
    //     if(!isExtended) {
    //         setRowSelected(false);
    //     }
    // },[isExtended])
    const [shipperId, setShipperId] = useState();
    const shipperIdRef = useRef(shipperId);
    
    const getShipperDetails = (params) => {
        if (!params) return;
    
        const newshipperId = parseInt(params.row.id);
        // Only update state if the shipperId has actually changed
        if (shipperIdRef.current !== newshipperId) {
            setShipperId(newshipperId);
            shipperIdRef.current = newshipperId; // Update the ref to the new value
        }
        setIsExtended(true);
    };
    
    useEffect(() => {
        props.replaceView({
            ...props.config,
            topRight:<WareHouseDropdown {...props.warehouseModal}/>,
            gridOptions:gridOptions,
            mobileActions:<GridActions handleOpen={() => setAddModalOpen(true)}></GridActions>
        });

        return () => {
            // console.log("unmounting warehouse");
        }
    },[]);

    
    const columns = [
        // {field: "type", headerName: t("type"), flex:1, renderCell : (cellValues) => {
        //     return (
                
        //     )
        // }},
        {field: "name", headerName: t("name") , flex:1, cellClassName: "name", renderCell : (cellValues) => {
            return (
                <>
                <Box sx={{ paddingRight: '10px' }}>
                    {cellValues.row.type === "postnl" ? <PostNLLogo width={30} height={30}/> : null}
                    {cellValues.row.type === "dhl" ? <DhlLogo  width={30} height={30}/> : null}
                    {cellValues.row.type === "dpd" ? <DpdLogo width={30} height={30}/> : null}
                    {cellValues.row.type === "bol" ? <BolLogo width={30} height={30}/> : null}
                    {cellValues.row.type === "other" ? <QuestionMarkOutlined width={30} height={30}/> : null}
                </Box>
                <Text semibold variant={theme.palette.mode === "dark" ? "blue" : "primary"}>{cellValues.value}</Text>
                </>
            )
        }},
        {field: "reference", headerName: t("reference"), flex:1 },
        {field: "maxLength", headerName: t("max_size"), flex:1, renderCell : (cellValues) => {
            return (
            <Box>
                <Text>
                    {`${cellValues.row.maxWidth}mm x ${cellValues.row.maxLength}mm x ${cellValues.row.maxHeight}mm (${cellValues.row.maxVolume} mm3)`}
                </Text>
            </Box>
            )
        }},
        {field: "maxWeight", headerName: t("max_weight"), flex: 1, renderCell: (cellValues) => {
            return (
                <Box>
                    <Text>
                        {cellValues.value ? `${cellValues.value} ${t("gram")}` : "-"}
                    </Text>
                </Box>
            )
        }},
        {field: "standard_time", headerName: t("pickup_times"), flex: 1, renderCell: (cellValues) => {
            return (
                <Box>
                    <Text>
                        {`${cellValues.row.standard_time ? new Date(new Date(cellValues.row.standard_time).getTime() - (60 * 60 * 1000)).toLocaleString('en-US', {hour: '2-digit', minute:'2-digit', hour12: false}) : '-'} | ${cellValues.row.standard_time_saturday ? new Date(new Date(cellValues.row.standard_time_saturday).getTime() - (60 * 60 * 1000)).toLocaleString('en-US', {hour: '2-digit', minute:'2-digit', hour12: false}) : '-'} | ${cellValues.row.standard_time_sunday ? new Date(new Date(cellValues.row.standard_time_sunday).getTime() - (60 * 60 * 1000)).toLocaleString('en-US', {hour: '2-digit', minute:'2-digit', hour12: false}) : '-'}`}
                    </Text>
                </Box>
            )
        }},
         {field: "alternativeAddress", headerName: t("alternativeAddress"), flex:1, renderCell: (cellValues) => {
            return (
                <Box>
                    <Text>
                        {cellValues.value ? (
                            <GridCheckIcon sx={{ color: 'green' }} />
                        ) : (
                            <ClearIcon sx={{ color: 'rgba(128, 128, 128, 0.5)' }} />
                        )}
                    </Text>
                </Box>
            )
        }},
        
        // {field: "weight", headerName: "Weight including label", flex:1, renderCell : (cellValues) => {
        //     return (
        //         <Text>{cellValues.value}</Text>
        //     )
        // }},
        // {field: "length", headerName: t("dimensions_lwh"), flex:1, renderCell : (cellValues) => {
        //     return (
        //         <Text>{`${cellValues.row.length} x ${cellValues.row.width} x ${cellValues.row.height}`}</Text>
        //     )
        // }},
        // {field: "barcodes", headerName: "Nr. of barcodes", flex:1, renderCell : (cellValues) => {
        //     return (
        //         <Text>{cellValues.value}</Text>
        //     )
        // }},
        {
            field: "phoneView", 
            headerName: "PhoneView",
            cellClassName: "phone-column--cell",
            renderCell: (cellValues) => {
                // console.log(cellValues.row);
               // return (
                    // <AllCarriersCard {...cellValues.row} />
               // )
            }
        }
    ];

    useImperativeHandle(ref, () => ({
    }));

    const handleRowClick = (params) => {
        setIsExtended(true);
    };
    
    return (
        <Box
            sx={{
                height:1,
                "@media screen and (max-width: 47.9375em)" : {
                    paddingTop:0,
                    paddingX:0,
                }
            }}
        >
            
                {/* <GridActions></GridActions> */}
            <Box
                sx={{
                    background: theme.palette.mode === "dark" ? colors.grey[200] : colors.grey[0],
                    flex:1,
                    borderTopLeftRadius:16,
                    borderTopRightRadius:16,
                    "@media screen and (max-width: 48em)" : {
                        // display:"none"
                        background: theme.palette.mode === "dark" ? colors.grey[200] : colors.grey[0],
                    }
                }}
            >
                <ShDataGrid2 key={theme.palette.mode}
                    content={useGetShippersQuery}
                    gridActions={<GridActions handleOpen={() => setAddModalOpen(true)}></GridActions>} 
                    title={t("overview")}
                    gridOptions={gridOptions} 
                    isSidepanelGrid
                    columns={columns} 
                    onRowClick={getShipperDetails}
                    rows={data}>
                </ShDataGrid2>
            </Box>

            <MpAddShipper onOpen={() => addModal.setOpen(true)} onClose={() => addModal.setOpen(false)} open={addModal.open} handleClose={handleClose}/>
            {/* <SpCarrierDetails isExtended={isExtended} setIsExtended={setIsExtended}/> */}
            { <SpShipperDetails
                isExtended={isExtended}
                setIsExtended={setIsExtended}
                shipperId={shipperId}
                // data={shipperData}                
            ></SpShipperDetails> }
        </Box>
    )
});


// const All = (apiRef,props) => {
//     return {
//         title: "All carriers",
//         subTitle: "All carriers registered",
//         icon: <LocalShippingOutlinedIcon/>,
//         view: <AllBody apiRef={apiRef}/>,
//         topRight:<WareHouseDropdown {...props.warehouseModal}/>,
//         isSingleDataGrid: true,
//         apiRef: apiRef,
//         gridOptions:gridOptions,
//         mobileActions:<GridActions></GridActions>
//     }
// }

export default All;