
import WidgetsIcon from '@mui/icons-material/Widgets';
import CropFreeIcon from '@mui/icons-material/CropFree';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';

import WarehouseIcon from '@mui/icons-material/Warehouse';

export const spOrderDetailsConfigContent = {
    shipments:  {
        title: "shipments",
        icon: <WidgetsIcon/>,
    },
    jokers: {
        title: "jokers",
        icon: <WidgetsIcon/>,
    },
    logs: {
        title: 'logs',
        icon: <FormatListBulletedIcon/>,
        isSingleDataGrid: true,
    },
    orderLines: {
        title: 'orderlines',
        icon: <CropFreeIcon/>,
    },
    pickingLines: {
        title: 'pickinglines',
        icon: <WidgetsIcon/>,
        isSingleDataGrid: true,
    },
    replenish: {
        title: 'replenish',
        icon: <WidgetsIcon/>,
        isSingleDataGrid: true,
    },
    shipOrder: {
        title: 'ship_order',
        icon: <CropFreeIcon/>,
        options : [],
        status:{
            value: "Shipped",
            variant:"green",
            palette:"normal"
        },
    },
    splitOrder: {
        title: "split_order",
        icon: <WidgetsIcon/>,
    },
    writeOffs: {
        title: 'write_offs',
        icon: <CropFreeIcon/>,
    },
    status: {
        title: 'status',
        icon: <WarehouseIcon/>,
    }
};

export const spOrderDetailsConfig = (relation) => {
    if (relation) {
        return {
            status: spOrderDetailsConfigContent.status,
            shipOrder: spOrderDetailsConfigContent.shipOrder,
            orderLines: spOrderDetailsConfigContent.orderLines,
            writeOffs: spOrderDetailsConfigContent.writeOffs,
            shipments: spOrderDetailsConfigContent.shipments,
        };
    }
    return {
        orderLines: spOrderDetailsConfigContent.orderLines,
        pickingLines: spOrderDetailsConfigContent.pickingLines,
        replenish: spOrderDetailsConfigContent.replenish,
        shipOrder: spOrderDetailsConfigContent.shipOrder,
        writeOffs: spOrderDetailsConfigContent.writeOffs,
        shipments: spOrderDetailsConfigContent.shipments,
        jokers: spOrderDetailsConfigContent.jokers,
        logs: spOrderDetailsConfigContent.logs,
    };
}