import { Box, ButtonBase, useTheme } from "@mui/material";
import { chartTokens, tokens } from "../../../theme";
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import React, { useLayoutEffect, useState } from "react";
import Text from "../text/Text";
import ChartText from "../text/ChartText";
// import Dropdown from "../Dropdown";
import { DropdownBase } from "./Dropdown";
import cx from 'classnames';

export const InputDropdownContent = (props) => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const variant = props.variant ?? "default";


    const getTextForMultiple = () => {
        if(props.selected.length === 0) return typeof props.noSelection !== "undefined" ? props.noSelection : "Kies";
        if(props.selected.length === 1) return props.options[props.selected][props.displayName];
        
        return props.selected.length
    }

    const getBackground = () => {
        if(variant === "default") {
            return theme.palette.mode === "dark" ? colors.grey[400] : colors.bg["tertiary"];
        }
        if(variant=== "darker") {
            return theme.palette.mode === "dark" ? colors.grey[500] : colors.grey[200];
        }
    }

    const getPaddingRight = () => {
        if(variant === "default") return 1;
        if(variant=== "darker") return 0.5;

    }

    const getButtonBackground=  () => {
        if(variant === "default") {
            return null
        }
        if(variant === "darker") {
            return theme.palette.mode === "dark" ? colors.txt["secondary"] : colors.txt["primary"];
        }
    }
    const getButtonColor = () => {
        if(variant === "default") {
            return colors.txt["secondary"];
        }
        if(variant === "darker") {
            return "#fff";
        }
    }

    const getSingleValue = () => {
        if(props.selected === -1 || !props.options) {
            return typeof props.noSelection !== "undefined" ? props.noSelection : "Kies";
        }
        if(typeof props.options[props.selected] === "object") {
            return props.options[props.selected][props.displayName]
        }
        return props.options[props.selected]
    }

    console.log("props.error",props.error);

    return (
        <ButtonBase
            className={cx(props.className + " dsfsdjn", {
                'error': props.error,
            })}
            aria-describedby={props.describedBy} 
            onClick={props.onClick}

            TouchRippleProps={{ 
                style: { 
                    color: theme.palette.mode === "dark" ? colors.grey[500] : colors.grey[400]
                } 
            }} 

            sx={{
                display:"flex",
                alignItems:"center",
                justifyContent:"space-between",
                background: getBackground(),
                height:40,
                paddingRight: getPaddingRight(),
                paddingLeft: 2,
                borderRadius:5,
                width:1,
                "&.error" : {
                    // background: colors.red[100],
                    // // border: `1px solid ${colors.red[400]} !important`,
                    // borderColor: colors.red[400],
                    // borderStyle: 'solid',
                    // borderWidth: 1,
                    borderColor: `${colors.red[400]} !important`,
                    borderStyle: 'solid !important',
                    borderWidth: '1px !important',
                    background: `${colors.red[100]} !important`,
                }
                // mr:2,
            }}
        >
            {props.multiple ?
                <Box
                    sx={{
                        display:"flex",
                        alignItems:"center",
                        gap:1
                    }}
                >
                    <Text variant={props.error ? "red" : null} bold>{getTextForMultiple()}</Text>
                    {/* <ChartText mode={mode} color={color} bold>{getTextForMultiple()}</ChartText> */}
                    {props.selected.length > 1 ?
                        <Text variant={props.error ? "red" : null} light>geselecteerd</Text>
                    :null}
                    {/* <ChartText mode={mode} color={color} bold>{selected !== -1 ? props.options[selected][props.displayName] : "Alle"}</ChartText> */}
                </Box> 
            :
            
                <Text variant={props.error ? "red" : null}  bold>
                    {getSingleValue()}
                </Text>
                
                
            }
            <Box
                sx={{
                    display:"flex",
                    alignItems:"center",
                    justifyContent:"center",
                    transform:"rotate(90deg)",
                    width: 32,
                    height:32,
                    flexShrink:0,
                    borderRadius:4,
                    color: getButtonColor(),
                    background: getButtonBackground()
                }}
            >
                <ChevronRightIcon/>
            </Box>
        </ButtonBase>
    )
}

export const InputDropdown = (props) => {
    return (
        <DropdownBase {...props}>
            <InputDropdownContent {...props} variant={props.variant}/>
        </DropdownBase>
    )
}